/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Grid from "@mui/material/Grid";
import validator from "validator";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVFileInputMultiple from "components/LVFileInputMultiple";
import OTPModal from "modals/otpModal";
import { Icon } from "@mui/material";
import { transformStatusKyc } from "../../common/Util";
import {
  deleteKYCDocument,
  fetchKYCDocs,
  saveKYCDocument,
  saveKYCPhone,
  getCompanyKYCStatus,
  getCompany,
} from "../../actions/companyAction";
import serverList from "../../apis/serverList";
import LVFileInput from "components/LVFileInput";
import LVFileInputMultipleDisplay from "components/LVFileInputMultipleDisplay";

function RegisterBorrower() {
  const location = useLocation();
  const urlparams = new URLSearchParams(location.search);
  const [activeStep, setActiveStep] = useState(0);
  const [layout, setLayout] = useState(urlparams.get("layout") || "datadiri");
  const [isLoading, setLoading] = useState("");

  const [noHP, setNoHP] = useState("");
  const [showError, setShowError] = useState({
    noHP: false,
  });
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [ktpFile, setKtpFile] = useState(null);
  const [ktpSelfieFile, setKtpSelfieFile] = useState(null);
  const [fotoUsaha, setFotoUsaha] = useState(null);
  const [fotoUsaha2, setFotoUsaha2] = useState(null);
  const [fotoUsaha3, setFotoUsaha3] = useState(null);
  const [fotoUsaha4, setFotoUsaha4] = useState(null);
  const [fotoUsaha5, setFotoUsaha5] = useState(null);
  const [fotoUsaha6, setFotoUsaha6] = useState(null);
  const [aktaPendirian, setAktaPendirian] = useState(null);
  const [aktaPendirian2, setAktaPendirian2] = useState(null);
  const [aktaPendirian3, setAktaPendirian3] = useState(null);
  const [aktaPendirian4, setAktaPendirian4] = useState(null);
  const [aktaPendirian5, setAktaPendirian5] = useState(null);
  const [aktaPendirian6, setAktaPendirian6] = useState(null);
  const [aktaPerubahan, setAktaPerubahan] = useState(null);
  const [aktaPerubahan2, setAktaPerubahan2] = useState(null);
  const [aktaPerubahan3, setAktaPerubahan3] = useState(null);
  const [aktaPerubahan4, setAktaPerubahan4] = useState(null);
  const [aktaPerubahan5, setAktaPerubahan5] = useState(null);
  const [aktaPerubahan6, setAktaPerubahan6] = useState(null);
  const [aktaPendirianSK, setAktaPendirianSK] = useState(null);
  const [aktaPendirianSK2, setAktaPendirianSK2] = useState(null);
  const [aktaPendirianSK3, setAktaPendirianSK3] = useState(null);
  const [aktaPendirianSK4, setAktaPendirianSK4] = useState(null);
  const [aktaPendirianSK5, setAktaPendirianSK5] = useState(null);
  const [aktaPendirianSK6, setAktaPendirianSK6] = useState(null);
  const [aktaPerubahanSK, setAktaPerubahanSK] = useState(null);
  const [aktaPerubahanSK2, setAktaPerubahanSK2] = useState(null);
  const [aktaPerubahanSK3, setAktaPerubahanSK3] = useState(null);
  const [aktaPerubahanSK4, setAktaPerubahanSK4] = useState(null);
  const [aktaPerubahanSK5, setAktaPerubahanSK5] = useState(null);
  const [aktaPerubahanSK6, setAktaPerubahanSK6] = useState(null);
  const [siupNib, setSiupNib] = useState(null);
  const [siupNib2, setSiupNib2] = useState(null);
  const [siupNib3, setSiupNib3] = useState(null);
  const [siupNib4, setSiupNib4] = useState(null);
  const [siupNib5, setSiupNib5] = useState(null);
  const [siupNib6, setSiupNib6] = useState(null);
  const [npwp, setNpwp] = useState(null);
  const [npwp2, setNpwp2] = useState(null);
  const [npwp3, setNpwp3] = useState(null);
  const [npwp4, setNpwp4] = useState(null);
  const [npwp5, setNpwp5] = useState(null);
  const [npwp6, setNpwp6] = useState(null);
  const [ktpPengurus, setKtpPengurus] = useState(null);
  const [ktpPengurus2, setKtpPengurus2] = useState(null);
  const [ktpPengurus3, setKtpPengurus3] = useState(null);
  const [ktpPengurus4, setKtpPengurus4] = useState(null);
  const [ktpPengurus5, setKtpPengurus5] = useState(null);
  const [ktpPengurus6, setKtpPengurus6] = useState(null);
  const [ktpDireksi, setKtpDireksi] = useState(null);
  const [ktpDireksi2, setKtpDireksi2] = useState(null);
  const [ktpDireksi3, setKtpDireksi3] = useState(null);
  const [ktpDireksi4, setKtpDireksi4] = useState(null);
  const [ktpDireksi5, setKtpDireksi5] = useState(null);
  const [ktpDireksi6, setKtpDireksi6] = useState(null);
  const [npwpDireksi, setNpwpDireksi] = useState(null);
  const [npwpDireksi2, setNpwpDireksi2] = useState(null);
  const [npwpDireksi3, setNpwpDireksi3] = useState(null);
  const [npwpDireksi4, setNpwpDireksi4] = useState(null);
  const [npwpDireksi5, setNpwpDireksi5] = useState(null);
  const [npwpDireksi6, setNpwpDireksi6] = useState(null);
  const [ktpKomisaris, setKtpKomisaris] = useState(null);
  const [ktpKomisaris2, setKtpKomisaris2] = useState(null);
  const [ktpKomisaris3, setKtpKomisaris3] = useState(null);
  const [ktpKomisaris4, setKtpKomisaris4] = useState(null);
  const [ktpKomisaris5, setKtpKomisaris5] = useState(null);
  const [ktpKomisaris6, setKtpKomisaris6] = useState(null);
  const [npwpKomisaris, setNpwpKomisaris] = useState(null);
  const [npwpKomisaris2, setNpwpKomisaris2] = useState(null);
  const [npwpKomisaris3, setNpwpKomisaris3] = useState(null);
  const [npwpKomisaris4, setNpwpKomisaris4] = useState(null);
  const [npwpKomisaris5, setNpwpKomisaris5] = useState(null);
  const [npwpKomisaris6, setNpwpKomisaris6] = useState(null);
  const [laporanKeuangan, setLaporanKeuangan] = useState(null);
  const [laporanKeuangan2, setLaporanKeuangan2] = useState(null);
  const [laporanKeuangan3, setLaporanKeuangan3] = useState(null);
  const [laporanKeuangan4, setLaporanKeuangan4] = useState(null);
  const [laporanKeuangan5, setLaporanKeuangan5] = useState(null);
  const [laporanKeuangan6, setLaporanKeuangan6] = useState(null);
  const [laporanKeuangan1t, setLaporanKeuangan1t] = useState(null);
  const [laporanKeuangan1t2, setLaporanKeuangan1t2] = useState(null);
  const [laporanKeuangan1t3, setLaporanKeuangan1t3] = useState(null);
  const [laporanKeuangan1t4, setLaporanKeuangan1t4] = useState(null);
  const [laporanKeuangan1t5, setLaporanKeuangan1t5] = useState(null);
  const [laporanKeuangan1t6, setLaporanKeuangan1t6] = useState(null);
  const [laporanKeuangan2t, setLaporanKeuangan2t] = useState(null);
  const [laporanKeuangan2t2, setLaporanKeuangan2t2] = useState(null);
  const [laporanKeuangan2t3, setLaporanKeuangan2t3] = useState(null);
  const [laporanKeuangan2t4, setLaporanKeuangan2t4] = useState(null);
  const [laporanKeuangan2t5, setLaporanKeuangan2t5] = useState(null);
  const [laporanKeuangan2t6, setLaporanKeuangan2t6] = useState(null);
  const [rekeningKoran, setRekeningKoran] = useState(null);
  const [rekeningKoran2, setRekeningKoran2] = useState(null);
  const [rekeningKoran3, setRekeningKoran3] = useState(null);
  const [rekeningKoran4, setRekeningKoran4] = useState(null);
  const [rekeningKoran5, setRekeningKoran5] = useState(null);
  const [rekeningKoran6, setRekeningKoran6] = useState(null);
  const [rekeningKoran1b, setRekeningKoran1b] = useState(null);
  const [rekeningKoran1b2, setRekeningKoran1b2] = useState(null);
  const [rekeningKoran1b3, setRekeningKoran1b3] = useState(null);
  const [rekeningKoran1b4, setRekeningKoran1b4] = useState(null);
  const [rekeningKoran1b5, setRekeningKoran1b5] = useState(null);
  const [rekeningKoran1b6, setRekeningKoran1b6] = useState(null);
  const [rekeningKoran2b, setRekeningKoran2b] = useState(null);
  const [rekeningKoran2b2, setRekeningKoran2b2] = useState(null);
  const [rekeningKoran2b3, setRekeningKoran2b3] = useState(null);
  const [rekeningKoran2b4, setRekeningKoran2b4] = useState(null);
  const [rekeningKoran2b5, setRekeningKoran2b5] = useState(null);
  const [rekeningKoran2b6, setRekeningKoran2b6] = useState(null);
  const [rekeningKoran3b, setRekeningKoran3b] = useState(null);
  const [rekeningKoran3b2, setRekeningKoran3b2] = useState(null);
  const [rekeningKoran3b3, setRekeningKoran3b3] = useState(null);
  const [rekeningKoran3b4, setRekeningKoran3b4] = useState(null);
  const [rekeningKoran3b5, setRekeningKoran3b5] = useState(null);
  const [rekeningKoran3b6, setRekeningKoran3b6] = useState(null);
  const [rekeningKoran4b, setRekeningKoran4b] = useState(null);
  const [rekeningKoran4b2, setRekeningKoran4b2] = useState(null);
  const [rekeningKoran4b3, setRekeningKoran4b3] = useState(null);
  const [rekeningKoran4b4, setRekeningKoran4b4] = useState(null);
  const [rekeningKoran4b5, setRekeningKoran4b5] = useState(null);
  const [rekeningKoran4b6, setRekeningKoran4b6] = useState(null);
  const [rekeningKoran5b, setRekeningKoran5b] = useState(null);
  const [rekeningKoran5b2, setRekeningKoran5b2] = useState(null);
  const [rekeningKoran5b3, setRekeningKoran5b3] = useState(null);
  const [rekeningKoran5b4, setRekeningKoran5b4] = useState(null);
  const [rekeningKoran5b5, setRekeningKoran5b5] = useState(null);
  const [rekeningKoran5b6, setRekeningKoran5b6] = useState(null);
  const [rekeningKoran6b, setRekeningKoran6b] = useState(null);
  const [rekeningKoran6b2, setRekeningKoran6b2] = useState(null);
  const [rekeningKoran6b3, setRekeningKoran6b3] = useState(null);
  const [rekeningKoran6b4, setRekeningKoran6b4] = useState(null);
  const [rekeningKoran6b5, setRekeningKoran6b5] = useState(null);
  const [rekeningKoran6b6, setRekeningKoran6b6] = useState(null);
  const [suratPernyataan, setSuratPernyataan] = useState(null);
  const [helperMsg, setHelperMsg] = useState({
    noHP: "Format telepon tidak valid",
  });
  const [statusStep, setStatusStep] = useState(1);
  const [rejectNoHP, setRejectNoHP] = useState(false);

  const steps = [
    "Pengisian persyaratan pinjaman",
    "Verifikasi oleh Kentara",
    "Mendaftar ke pendana",
  ];

  const refs = {
    ktpFile: useRef(),
    ktpSelfieFile: useRef(),
    fotoUsaha: useRef(),
    fotoUsaha_2: useRef(),
    fotoUsaha_3: useRef(),
    fotoUsaha_4: useRef(),
    fotoUsaha_5: useRef(),
    fotoUsaha_6: useRef(),
    aktaPendirian: useRef(),
    aktaPendirian_2: useRef(),
    aktaPendirian_3: useRef(),
    aktaPendirian_4: useRef(),
    aktaPendirian_5: useRef(),
    aktaPendirian_6: useRef(),
    aktaPerubahan: useRef(),
    aktaPerubahan_2: useRef(),
    aktaPerubahan_3: useRef(),
    aktaPerubahan_4: useRef(),
    aktaPerubahan_5: useRef(),
    aktaPerubahan_6: useRef(),
    aktaPendirianSK: useRef(),
    aktaPendirianSK_2: useRef(),
    aktaPendirianSK_3: useRef(),
    aktaPendirianSK_4: useRef(),
    aktaPendirianSK_5: useRef(),
    aktaPendirianSK_6: useRef(),
    aktaPerubahanSK: useRef(),
    aktaPerubahanSK_2: useRef(),
    aktaPerubahanSK_3: useRef(),
    aktaPerubahanSK_4: useRef(),
    aktaPerubahanSK_5: useRef(),
    aktaPerubahanSK_6: useRef(),
    siupNib: useRef(),
    siupNib_2: useRef(),
    siupNib_3: useRef(),
    siupNib_4: useRef(),
    siupNib_5: useRef(),
    siupNib_6: useRef(),
    npwp: useRef(),
    npwp_2: useRef(),
    npwp_3: useRef(),
    npwp_4: useRef(),
    npwp_5: useRef(),
    npwp_6: useRef(),
    ktpPengurus: useRef(),
    ktpPengurus_2: useRef(),
    ktpPengurus_3: useRef(),
    ktpPengurus_4: useRef(),
    ktpPengurus_5: useRef(),
    ktpPengurus_6: useRef(),
    ktpDireksi: useRef(),
    ktpDireksi_2: useRef(),
    ktpDireksi_3: useRef(),
    ktpDireksi_4: useRef(),
    ktpDireksi_5: useRef(),
    ktpDireksi_6: useRef(),
    ktpKomisaris: useRef(),
    ktpKomisaris_2: useRef(),
    ktpKomisaris_3: useRef(),
    ktpKomisaris_4: useRef(),
    ktpKomisaris_5: useRef(),
    ktpKomisaris_6: useRef(),
    npwpDireksi: useRef(),
    npwpDireksi_2: useRef(),
    npwpDireksi_3: useRef(),
    npwpDireksi_4: useRef(),
    npwpDireksi_5: useRef(),
    npwpDireksi_6: useRef(),
    npwpKomisaris: useRef(),
    npwpKomisaris_2: useRef(),
    npwpKomisaris_3: useRef(),
    npwpKomisaris_4: useRef(),
    npwpKomisaris_5: useRef(),
    npwpKomisaris_6: useRef(),
    laporanKeuangan: useRef(),
    laporanKeuangan_2: useRef(),
    laporanKeuangan_3: useRef(),
    laporanKeuangan_4: useRef(),
    laporanKeuangan_5: useRef(),
    laporanKeuangan_6: useRef(),
    laporanKeuangan1t: useRef(),
    laporanKeuangan1t_2: useRef(),
    laporanKeuangan1t_3: useRef(),
    laporanKeuangan1t_4: useRef(),
    laporanKeuangan1t_5: useRef(),
    laporanKeuangan1t_6: useRef(),
    laporanKeuangan2t: useRef(),
    laporanKeuangan2t_2: useRef(),
    laporanKeuangan2t_3: useRef(),
    laporanKeuangan2t_4: useRef(),
    laporanKeuangan2t_5: useRef(),
    laporanKeuangan2t_6: useRef(),
    rekeningKoran: useRef(),
    rekeningKoran_2: useRef(),
    rekeningKoran_3: useRef(),
    rekeningKoran_4: useRef(),
    rekeningKoran_5: useRef(),
    rekeningKoran_6: useRef(),
    rekeningKoran1b: useRef(),
    rekeningKoran1b_2: useRef(),
    rekeningKoran1b_3: useRef(),
    rekeningKoran1b_4: useRef(),
    rekeningKoran1b_5: useRef(),
    rekeningKoran1b_6: useRef(),
    rekeningKoran2b: useRef(),
    rekeningKoran2b_2: useRef(),
    rekeningKoran2b_3: useRef(),
    rekeningKoran2b_4: useRef(),
    rekeningKoran2b_5: useRef(),
    rekeningKoran2b_6: useRef(),
    rekeningKoran3b: useRef(),
    rekeningKoran3b_2: useRef(),
    rekeningKoran3b_3: useRef(),
    rekeningKoran3b_4: useRef(),
    rekeningKoran3b_5: useRef(),
    rekeningKoran3b_6: useRef(),
    rekeningKoran4b: useRef(),
    rekeningKoran4b_2: useRef(),
    rekeningKoran4b_3: useRef(),
    rekeningKoran4b_4: useRef(),
    rekeningKoran4b_5: useRef(),
    rekeningKoran4b_6: useRef(),
    rekeningKoran5b: useRef(),
    rekeningKoran5b_2: useRef(),
    rekeningKoran5b_3: useRef(),
    rekeningKoran5b_4: useRef(),
    rekeningKoran5b_5: useRef(),
    rekeningKoran5b_6: useRef(),
    rekeningKoran6b: useRef(),
    rekeningKoran6b_2: useRef(),
    rekeningKoran6b_3: useRef(),
    rekeningKoran6b_4: useRef(),
    rekeningKoran6b_5: useRef(),
    rekeningKoran6b_6: useRef(),
    suratPernyataan: useRef(),
  };

  const [listStatus, setListStatus] = useState({
    noHP: "",
    ktpFile: "",
    ktpSelfieFile: "",
    aktaPendirian: "",
    aktaPerubahan: "",
    aktaPendirianSK: "",
    aktaPerubahanSK: "",
    siupNib: "",
    npwp: "",
    ktpPengurus: "",
    ktpDireksi: "",
    ktpKomisaris: "",
    npwpDireksi: "",
    npwpKomisaris: "",
    laporanKeuangan: "",
    laporanKeuangan1t: "",
    laporanKeuangan2t: "",
    rekeningKoran: "",
    rekeningKoran1b: "",
    rekeningKoran2b: "",
    rekeningKoran3b: "",
    rekeningKoran4b: "",
    rekeningKoran5b: "",
    rekeningKoran6b: "",
    suratPernyataan: "",
  });

  const anCompany = useSelector((state) => state.anCompany, shallowEqual);

  const listKYCDocs = useSelector((state) => state.listKYCDocs, shallowEqual);

  const message = useSelector((state) => state.message, shallowEqual);

  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompany());
    dispatch(getCompanyKYCStatus());
    dispatch(fetchKYCDocs());
  }, []);

  useEffect(() => {
    if (anCompany.type !== undefined) {
      if (anCompany.type !== "PO") {
        if (listKYCDocs.phone !== undefined) {
          if (listKYCDocs.phone.data !== undefined) {
            setNoHP(listKYCDocs.phone.data);
            if (listKYCDocs.phone.status === "rejected") {
              setRejectNoHP(true);
            } else {
              setRejectNoHP(false);
            }
          } else {
            setNoHP("");
          }
        } else {
          setNoHP("");
        }

        if (listKYCDocs.pic_ktp !== undefined) {
          setKtpFile(serverList.company + listKYCDocs.pic_ktp.url);
        } else {
          setKtpFile(null);
        }

        if (listKYCDocs.foto_usaha !== undefined) {
          setFotoUsaha({
            id: listKYCDocs.foto_usaha.id,
            img: serverList.company + listKYCDocs.foto_usaha.url,
          });
        } else {
          setFotoUsaha(null);
        }

        if (listKYCDocs.foto_usaha_2 !== undefined) {
          setFotoUsaha2({
            id: listKYCDocs.foto_usaha_2.id,
            img: serverList.company + listKYCDocs.foto_usaha_2.url,
          });
        } else {
          setFotoUsaha2(null);
        }

        if (listKYCDocs.foto_usaha_3 !== undefined) {
          setFotoUsaha3({
            id: listKYCDocs.foto_usaha_3.id,
            img: serverList.company + listKYCDocs.foto_usaha_3.url,
          });
        } else {
          setFotoUsaha3(null);
        }

        if (listKYCDocs.foto_usaha_4 !== undefined) {
          setFotoUsaha4({
            id: listKYCDocs.foto_usaha_4.id,
            img: serverList.company + listKYCDocs.foto_usaha_4.url,
          });
        } else {
          setFotoUsaha4(null);
        }

        if (listKYCDocs.foto_usaha_5 !== undefined) {
          setFotoUsaha5({
            id: listKYCDocs.foto_usaha_5.id,
            img: serverList.company + listKYCDocs.foto_usaha_5.url,
          });
        } else {
          setFotoUsaha5(null);
        }

        if (listKYCDocs.foto_usaha_6 !== undefined) {
          setFotoUsaha6({
            id: listKYCDocs.foto_usaha_6.id,
            img: serverList.company + listKYCDocs.foto_usaha_6.url,
          });
        } else {
          setFotoUsaha6(null);
        }

        if (listKYCDocs.akta_pendirian !== undefined) {
          setAktaPendirian(listKYCDocs.akta_pendirian);
        } else {
          setAktaPendirian(null);
        }

        if (listKYCDocs.akta_pendirian_2 !== undefined) {
          setAktaPendirian2(listKYCDocs.akta_pendirian_2);
        } else {
          setAktaPendirian2(null);
        }

        if (listKYCDocs.akta_pendirian_3 !== undefined) {
          setAktaPendirian3(listKYCDocs.akta_pendirian_3);
        } else {
          setAktaPendirian3(null);
        }

        if (listKYCDocs.akta_pendirian_4 !== undefined) {
          setAktaPendirian4(listKYCDocs.akta_pendirian_4);
        } else {
          setAktaPendirian4(null);
        }

        if (listKYCDocs.akta_pendirian_5 !== undefined) {
          setAktaPendirian5(listKYCDocs.akta_pendirian_5);
        } else {
          setAktaPendirian5(null);
        }

        if (listKYCDocs.akta_pendirian_6 !== undefined) {
          setAktaPendirian6(listKYCDocs.akta_pendirian_6);
        } else {
          setAktaPendirian6(null);
        }

        if (listKYCDocs.akta_pendirian_sk !== undefined) {
          setAktaPendirianSK(listKYCDocs.akta_pendirian_sk);
        } else {
          setAktaPendirianSK(null);
        }

        if (listKYCDocs.akta_pendirian_sk_2 !== undefined) {
          setAktaPendirianSK2(listKYCDocs.akta_pendirian_sk_2);
        } else {
          setAktaPendirianSK2(null);
        }

        if (listKYCDocs.akta_pendirian_sk_3 !== undefined) {
          setAktaPendirianSK3(listKYCDocs.akta_pendirian_sk_3);
        } else {
          setAktaPendirianSK3(null);
        }
        if (listKYCDocs.akta_pendirian_sk_4 !== undefined) {
          setAktaPendirianSK4(listKYCDocs.akta_pendirian_sk_4);
        } else {
          setAktaPendirianSK4(null);
        }
        if (listKYCDocs.akta_pendirian_sk_5 !== undefined) {
          setAktaPendirianSK5(listKYCDocs.akta_pendirian_sk_5);
        } else {
          setAktaPendirianSK5(null);
        }
        if (listKYCDocs.akta_pendirian_sk_6 !== undefined) {
          setAktaPendirianSK6(listKYCDocs.akta_pendirian_sk_6);
        } else {
          setAktaPendirianSK6(null);
        }

        if (listKYCDocs.akta_perubahan !== undefined) {
          setAktaPerubahan(listKYCDocs.akta_perubahan);
        } else {
          setAktaPerubahan(null);
        }

        if (listKYCDocs.akta_perubahan_2 !== undefined) {
          setAktaPerubahan2(listKYCDocs.akta_perubahan_2);
        } else {
          setAktaPerubahan2(null);
        }

        if (listKYCDocs.akta_perubahan_3 !== undefined) {
          setAktaPerubahan3(listKYCDocs.akta_perubahan_3);
        } else {
          setAktaPerubahan3(null);
        }

        if (listKYCDocs.akta_perubahan_4 !== undefined) {
          setAktaPerubahan4(listKYCDocs.akta_perubahan_4);
        } else {
          setAktaPerubahan4(null);
        }

        if (listKYCDocs.akta_perubahan_5 !== undefined) {
          setAktaPerubahan5(listKYCDocs.akta_perubahan_5);
        } else {
          setAktaPerubahan5(null);
        }

        if (listKYCDocs.akta_perubahan_6 !== undefined) {
          setAktaPerubahan6(listKYCDocs.akta_perubahan_6);
        } else {
          setAktaPerubahan6(null);
        }

        if (listKYCDocs.akta_perubahan_sk !== undefined) {
          setAktaPerubahanSK(listKYCDocs.akta_perubahan_sk);
        } else {
          setAktaPerubahanSK(null);
        }

        if (listKYCDocs.akta_perubahan_sk_2 !== undefined) {
          setAktaPerubahanSK2(listKYCDocs.akta_perubahan_sk_2);
        } else {
          setAktaPerubahanSK2(null);
        }

        if (listKYCDocs.akta_perubahan_sk_3 !== undefined) {
          setAktaPerubahanSK3(listKYCDocs.akta_perubahan_sk_3);
        } else {
          setAktaPerubahanSK3(null);
        }

        if (listKYCDocs.akta_perubahan_sk_4 !== undefined) {
          setAktaPerubahanSK4(listKYCDocs.akta_perubahan_sk_4);
        } else {
          setAktaPerubahanSK4(null);
        }

        if (listKYCDocs.akta_perubahan_sk_5 !== undefined) {
          setAktaPerubahanSK5(listKYCDocs.akta_perubahan_sk_5);
        } else {
          setAktaPerubahanSK5(null);
        }

        if (listKYCDocs.akta_perubahan_sk_6 !== undefined) {
          setAktaPerubahanSK6(listKYCDocs.akta_perubahan_sk_6);
        } else {
          setAktaPerubahanSK6(null);
        }

        if (listKYCDocs.siup_nib !== undefined) {
          setSiupNib(listKYCDocs.siup_nib);
        } else {
          setSiupNib(null);
        }

        if (listKYCDocs.siup_nib_2 !== undefined) {
          setSiupNib2(listKYCDocs.siup_nib_2);
        } else {
          setSiupNib2(null);
        }

        if (listKYCDocs.siup_nib_3 !== undefined) {
          setSiupNib3(listKYCDocs.siup_nib_3);
        } else {
          setSiupNib3(null);
        }

        if (listKYCDocs.siup_nib_4 !== undefined) {
          setSiupNib4(listKYCDocs.siup_nib_4);
        } else {
          setSiupNib4(null);
        }

        if (listKYCDocs.siup_nib_5 !== undefined) {
          setSiupNib5(listKYCDocs.siup_nib_5);
        } else {
          setSiupNib5(null);
        }

        if (listKYCDocs.siup_nib_6 !== undefined) {
          setSiupNib6(listKYCDocs.siup_nib_6);
        } else {
          setSiupNib6(null);
        }

        if (listKYCDocs.npwp !== undefined) {
          setNpwp(listKYCDocs.npwp);
        } else {
          setNpwp(null);
        }

        if (listKYCDocs.npwp_2 !== undefined) {
          setNpwp2(listKYCDocs.npwp_2);
        } else {
          setNpwp2(null);
        }

        if (listKYCDocs.npwp_3 !== undefined) {
          setNpwp3(listKYCDocs.npwp_3);
        } else {
          setNpwp3(null);
        }

        if (listKYCDocs.npwp_4 !== undefined) {
          setNpwp4(listKYCDocs.npwp_4);
        } else {
          setNpwp4(null);
        }

        if (listKYCDocs.npwp_5 !== undefined) {
          setNpwp5(listKYCDocs.npwp_5);
        } else {
          setNpwp5(null);
        }

        if (listKYCDocs.npwp_6 !== undefined) {
          setNpwp6(listKYCDocs.npwp_6);
        } else {
          setNpwp6(null);
        }

        if (listKYCDocs.pengurus_ktp !== undefined) {
          setKtpPengurus(listKYCDocs.pengurus_ktp);
        } else {
          setKtpPengurus(null);
        }

        if (listKYCDocs.pengurus_ktp_2 !== undefined) {
          setKtpPengurus2(listKYCDocs.pengurus_ktp_2);
        } else {
          setKtpPengurus2(null);
        }

        if (listKYCDocs.pengurus_ktp_3 !== undefined) {
          setKtpPengurus3(listKYCDocs.pengurus_ktp_3);
        } else {
          setKtpPengurus3(null);
        }

        if (listKYCDocs.pengurus_ktp_4 !== undefined) {
          setKtpPengurus4(listKYCDocs.pengurus_ktp_4);
        } else {
          setKtpPengurus4(null);
        }

        if (listKYCDocs.pengurus_ktp_5 !== undefined) {
          setKtpPengurus5(listKYCDocs.pengurus_ktp_5);
        } else {
          setKtpPengurus5(null);
        }

        if (listKYCDocs.pengurus_ktp_6 !== undefined) {
          setKtpPengurus6(listKYCDocs.pengurus_ktp_6);
        } else {
          setKtpPengurus6(null);
        }

        if (listKYCDocs.direksi_ktp !== undefined) {
          setKtpDireksi(listKYCDocs.direksi_ktp);
        } else {
          setKtpDireksi(null);
        }

        if (listKYCDocs.direksi_ktp_2 !== undefined) {
          setKtpDireksi2(listKYCDocs.direksi_ktp_2);
        } else {
          setKtpDireksi2(null);
        }

        if (listKYCDocs.direksi_ktp_3 !== undefined) {
          setKtpDireksi3(listKYCDocs.direksi_ktp_3);
        } else {
          setKtpDireksi3(null);
        }

        if (listKYCDocs.direksi_ktp_4 !== undefined) {
          setKtpDireksi4(listKYCDocs.direksi_ktp_4);
        } else {
          setKtpDireksi4(null);
        }

        if (listKYCDocs.direksi_ktp_5 !== undefined) {
          setKtpDireksi5(listKYCDocs.direksi_ktp_5);
        } else {
          setKtpDireksi5(null);
        }

        if (listKYCDocs.direksi_ktp_6 !== undefined) {
          setKtpDireksi6(listKYCDocs.direksi_ktp_6);
        } else {
          setKtpDireksi6(null);
        }

        if (listKYCDocs.direksi_npwp !== undefined) {
          setNpwpDireksi(listKYCDocs.direksi_npwp);
        } else {
          setNpwpDireksi(null);
        }

        if (listKYCDocs.direksi_npwp_2 !== undefined) {
          setNpwpDireksi2(listKYCDocs.direksi_npwp_2);
        } else {
          setNpwpDireksi2(null);
        }

        if (listKYCDocs.direksi_npwp_3 !== undefined) {
          setNpwpDireksi3(listKYCDocs.direksi_npwp_3);
        } else {
          setNpwpDireksi3(null);
        }

        if (listKYCDocs.direksi_npwp_4 !== undefined) {
          setNpwpDireksi4(listKYCDocs.direksi_npwp_4);
        } else {
          setNpwpDireksi4(null);
        }

        if (listKYCDocs.direksi_npwp_5 !== undefined) {
          setNpwpDireksi5(listKYCDocs.direksi_npwp_5);
        } else {
          setNpwpDireksi5(null);
        }

        if (listKYCDocs.direksi_npwp_6 !== undefined) {
          setNpwpDireksi6(listKYCDocs.direksi_npwp_6);
        } else {
          setNpwpDireksi6(null);
        }

        if (listKYCDocs.komisaris_ktp !== undefined) {
          setKtpKomisaris(listKYCDocs.komisaris_ktp);
        } else {
          setKtpKomisaris(null);
        }

        if (listKYCDocs.komisaris_ktp_2 !== undefined) {
          setKtpKomisaris2(listKYCDocs.komisaris_ktp_2);
        } else {
          setKtpKomisaris2(null);
        }

        if (listKYCDocs.komisaris_ktp_3 !== undefined) {
          setKtpKomisaris3(listKYCDocs.komisaris_ktp_3);
        } else {
          setKtpKomisaris3(null);
        }

        if (listKYCDocs.komisaris_ktp_4 !== undefined) {
          setKtpKomisaris4(listKYCDocs.komisaris_ktp_4);
        } else {
          setKtpKomisaris4(null);
        }

        if (listKYCDocs.komisaris_ktp_5 !== undefined) {
          setKtpKomisaris5(listKYCDocs.komisaris_ktp_5);
        } else {
          setKtpKomisaris5(null);
        }

        if (listKYCDocs.komisaris_ktp_6 !== undefined) {
          setKtpKomisaris6(listKYCDocs.komisaris_ktp_6);
        } else {
          setKtpKomisaris6(null);
        }

        if (listKYCDocs.komisaris_npwp !== undefined) {
          setNpwpKomisaris(listKYCDocs.komisaris_npwp);
        } else {
          setNpwpKomisaris(null);
        }

        if (listKYCDocs.komisaris_npwp_2 !== undefined) {
          setNpwpKomisaris2(listKYCDocs.komisaris_npwp_2);
        } else {
          setNpwpKomisaris2(null);
        }

        if (listKYCDocs.komisaris_npwp_3 !== undefined) {
          setNpwpKomisaris3(listKYCDocs.komisaris_npwp_3);
        } else {
          setNpwpKomisaris3(null);
        }

        if (listKYCDocs.komisaris_npwp_4 !== undefined) {
          setNpwpKomisaris4(listKYCDocs.komisaris_npwp_4);
        } else {
          setNpwpKomisaris4(null);
        }

        if (listKYCDocs.komisaris_npwp_5 !== undefined) {
          setNpwpKomisaris5(listKYCDocs.komisaris_npwp_5);
        } else {
          setNpwpKomisaris5(null);
        }

        if (listKYCDocs.komisaris_npwp_6 !== undefined) {
          setNpwpKomisaris6(listKYCDocs.komisaris_npwp_6);
        } else {
          setNpwpKomisaris6(null);
        }

        if (listKYCDocs.laporan_keuangan !== undefined) {
          setLaporanKeuangan(listKYCDocs.laporan_keuangan);
        } else {
          setLaporanKeuangan(null);
        }

        if (listKYCDocs.laporan_keuangan_2 !== undefined) {
          setLaporanKeuangan2(listKYCDocs.laporan_keuangan_2);
        } else {
          setLaporanKeuangan2(null);
        }

        if (listKYCDocs.laporan_keuangan_3 !== undefined) {
          setLaporanKeuangan3(listKYCDocs.laporan_keuangan_3);
        } else {
          setLaporanKeuangan3(null);
        }

        if (listKYCDocs.laporan_keuangan_4 !== undefined) {
          setLaporanKeuangan4(listKYCDocs.laporan_keuangan_4);
        } else {
          setLaporanKeuangan4(null);
        }

        if (listKYCDocs.laporan_keuangan_5 !== undefined) {
          setLaporanKeuangan5(listKYCDocs.laporan_keuangan_5);
        } else {
          setLaporanKeuangan5(null);
        }

        if (listKYCDocs.laporan_keuangan_6 !== undefined) {
          setLaporanKeuangan6(listKYCDocs.laporan_keuangan_6);
        } else {
          setLaporanKeuangan6(null);
        }

        if (listKYCDocs.laporan_keuangan_1t !== undefined) {
          setLaporanKeuangan1t(listKYCDocs.laporan_keuangan_1t);
        } else {
          setLaporanKeuangan1t(null);
        }

        if (listKYCDocs.laporan_keuangan_1t_2 !== undefined) {
          setLaporanKeuangan1t2(listKYCDocs.laporan_keuangan_1t_2);
        } else {
          setLaporanKeuangan1t2(null);
        }

        if (listKYCDocs.laporan_keuangan_1t_3 !== undefined) {
          setLaporanKeuangan1t3(listKYCDocs.laporan_keuangan_1t_3);
        } else {
          setLaporanKeuangan1t3(null);
        }

        if (listKYCDocs.laporan_keuangan_1t_4 !== undefined) {
          setLaporanKeuangan1t4(listKYCDocs.laporan_keuangan_1t_4);
        } else {
          setLaporanKeuangan1t4(null);
        }

        if (listKYCDocs.laporan_keuangan_1t_5 !== undefined) {
          setLaporanKeuangan1t5(listKYCDocs.laporan_keuangan_1t_5);
        } else {
          setLaporanKeuangan1t5(null);
        }

        if (listKYCDocs.laporan_keuangan_1t_6 !== undefined) {
          setLaporanKeuangan1t6(listKYCDocs.laporan_keuangan_1t_6);
        } else {
          setLaporanKeuangan1t6(null);
        }

        if (listKYCDocs.laporan_keuangan_2t !== undefined) {
          setLaporanKeuangan2t(listKYCDocs.laporan_keuangan_2t);
        } else {
          setLaporanKeuangan2t(null);
        }

        if (listKYCDocs.laporan_keuangan_2t_2 !== undefined) {
          setLaporanKeuangan2t2(listKYCDocs.laporan_keuangan_2t_2);
        } else {
          setLaporanKeuangan2t2(null);
        }

        if (listKYCDocs.laporan_keuangan_2t_3 !== undefined) {
          setLaporanKeuangan2t3(listKYCDocs.laporan_keuangan_2t_3);
        } else {
          setLaporanKeuangan2t3(null);
        }

        if (listKYCDocs.laporan_keuangan_2t_4 !== undefined) {
          setLaporanKeuangan2t4(listKYCDocs.laporan_keuangan_2t_4);
        } else {
          setLaporanKeuangan2t4(null);
        }

        if (listKYCDocs.laporan_keuangan_2t_5 !== undefined) {
          setLaporanKeuangan2t5(listKYCDocs.laporan_keuangan_2t_5);
        } else {
          setLaporanKeuangan2t5(null);
        }

        if (listKYCDocs.laporan_keuangan_2t_6 !== undefined) {
          setLaporanKeuangan2t6(listKYCDocs.laporan_keuangan_2t_6);
        } else {
          setLaporanKeuangan2t6(null);
        }

        if (listKYCDocs.rekening_koran !== undefined) {
          setRekeningKoran(listKYCDocs.rekening_koran);
        } else {
          setRekeningKoran(null);
        }

        if (listKYCDocs.rekening_koran_2 !== undefined) {
          setRekeningKoran2(listKYCDocs.rekening_koran_2);
        } else {
          setRekeningKoran2(null);
        }

        if (listKYCDocs.rekening_koran_3 !== undefined) {
          setRekeningKoran3(listKYCDocs.rekening_koran_3);
        } else {
          setRekeningKoran3(null);
        }

        if (listKYCDocs.rekening_koran_4 !== undefined) {
          setRekeningKoran4(listKYCDocs.rekening_koran_4);
        } else {
          setRekeningKoran4(null);
        }

        if (listKYCDocs.rekening_koran_5 !== undefined) {
          setRekeningKoran5(listKYCDocs.rekening_koran_5);
        } else {
          setRekeningKoran5(null);
        }

        if (listKYCDocs.rekening_koran_6 !== undefined) {
          setRekeningKoran6(listKYCDocs.rekening_koran_6);
        } else {
          setRekeningKoran6(null);
        }

        if (listKYCDocs.rekening_koran_1b !== undefined) {
          setRekeningKoran1b(listKYCDocs.rekening_koran_1b);
        } else {
          setRekeningKoran1b(null);
        }

        if (listKYCDocs.rekening_koran_1b_2 !== undefined) {
          setRekeningKoran1b2(listKYCDocs.rekening_koran_1b_2);
        } else {
          setRekeningKoran1b2(null);
        }

        if (listKYCDocs.rekening_koran_1b_3 !== undefined) {
          setRekeningKoran1b3(listKYCDocs.rekening_koran_1b_3);
        } else {
          setRekeningKoran1b3(null);
        }

        if (listKYCDocs.rekening_koran_1b_4 !== undefined) {
          setRekeningKoran1b4(listKYCDocs.rekening_koran_1b_4);
        } else {
          setRekeningKoran1b4(null);
        }

        if (listKYCDocs.rekening_koran_1b_5 !== undefined) {
          setRekeningKoran1b5(listKYCDocs.rekening_koran_1b_5);
        } else {
          setRekeningKoran1b5(null);
        }

        if (listKYCDocs.rekening_koran_1b_6 !== undefined) {
          setRekeningKoran1b6(listKYCDocs.rekening_koran_1b_6);
        } else {
          setRekeningKoran1b6(null);
        }

        if (listKYCDocs.rekening_koran_2b !== undefined) {
          setRekeningKoran2b(listKYCDocs.rekening_koran_2b);
        } else {
          setRekeningKoran2b(null);
        }

        if (listKYCDocs.rekening_koran_2b_2 !== undefined) {
          setRekeningKoran2b2(listKYCDocs.rekening_koran_2b_2);
        } else {
          setRekeningKoran2b2(null);
        }

        if (listKYCDocs.rekening_koran_2b_3 !== undefined) {
          setRekeningKoran2b3(listKYCDocs.rekening_koran_2b_3);
        } else {
          setRekeningKoran2b3(null);
        }

        if (listKYCDocs.rekening_koran_2b_4 !== undefined) {
          setRekeningKoran2b4(listKYCDocs.rekening_koran_2b_4);
        } else {
          setRekeningKoran2b4(null);
        }

        if (listKYCDocs.rekening_koran_2b_5 !== undefined) {
          setRekeningKoran2b5(listKYCDocs.rekening_koran_2b_5);
        } else {
          setRekeningKoran2b5(null);
        }

        if (listKYCDocs.rekening_koran_2b_6 !== undefined) {
          setRekeningKoran2b6(listKYCDocs.rekening_koran_2b_6);
        } else {
          setRekeningKoran2b6(null);
        }

        if (listKYCDocs.rekening_koran_3b !== undefined) {
          setRekeningKoran3b(listKYCDocs.rekening_koran_3b);
        } else {
          setRekeningKoran3b(null);
        }

        if (listKYCDocs.rekening_koran_3b_2 !== undefined) {
          setRekeningKoran3b2(listKYCDocs.rekening_koran_3b_2);
        } else {
          setRekeningKoran3b2(null);
        }

        if (listKYCDocs.rekening_koran_3b_3 !== undefined) {
          setRekeningKoran3b3(listKYCDocs.rekening_koran_3b_3);
        } else {
          setRekeningKoran3b3(null);
        }

        if (listKYCDocs.rekening_koran_3b_4 !== undefined) {
          setRekeningKoran3b4(listKYCDocs.rekening_koran_3b_4);
        } else {
          setRekeningKoran3b4(null);
        }

        if (listKYCDocs.rekening_koran_3b_5 !== undefined) {
          setRekeningKoran3b5(listKYCDocs.rekening_koran_3b_5);
        } else {
          setRekeningKoran3b5(null);
        }

        if (listKYCDocs.rekening_koran_3b_6 !== undefined) {
          setRekeningKoran3b6(listKYCDocs.rekening_koran_3b_6);
        } else {
          setRekeningKoran3b6(null);
        }

        if (listKYCDocs.rekening_koran_4b !== undefined) {
          setRekeningKoran4b(listKYCDocs.rekening_koran_4b);
        } else {
          setRekeningKoran4b(null);
        }

        if (listKYCDocs.rekening_koran_4b_2 !== undefined) {
          setRekeningKoran4b2(listKYCDocs.rekening_koran_4b_2);
        } else {
          setRekeningKoran4b2(null);
        }

        if (listKYCDocs.rekening_koran_4b_3 !== undefined) {
          setRekeningKoran4b3(listKYCDocs.rekening_koran_4b_3);
        } else {
          setRekeningKoran4b3(null);
        }

        if (listKYCDocs.rekening_koran_4b_4 !== undefined) {
          setRekeningKoran4b4(listKYCDocs.rekening_koran_4b_4);
        } else {
          setRekeningKoran4b4(null);
        }

        if (listKYCDocs.rekening_koran_4b_5 !== undefined) {
          setRekeningKoran4b5(listKYCDocs.rekening_koran_4b_5);
        } else {
          setRekeningKoran4b5(null);
        }

        if (listKYCDocs.rekening_koran_4b_6 !== undefined) {
          setRekeningKoran4b6(listKYCDocs.rekening_koran_4b_6);
        } else {
          setRekeningKoran4b6(null);
        }

        if (listKYCDocs.rekening_koran_5b !== undefined) {
          setRekeningKoran5b(listKYCDocs.rekening_koran_5b);
        } else {
          setRekeningKoran5b(null);
        }

        if (listKYCDocs.rekening_koran_5b_2 !== undefined) {
          setRekeningKoran5b2(listKYCDocs.rekening_koran_5b_2);
        } else {
          setRekeningKoran5b2(null);
        }

        if (listKYCDocs.rekening_koran_5b_3 !== undefined) {
          setRekeningKoran5b3(listKYCDocs.rekening_koran_5b_3);
        } else {
          setRekeningKoran5b3(null);
        }

        if (listKYCDocs.rekening_koran_5b_4 !== undefined) {
          setRekeningKoran5b4(listKYCDocs.rekening_koran_5b_4);
        } else {
          setRekeningKoran5b4(null);
        }

        if (listKYCDocs.rekening_koran_5b_5 !== undefined) {
          setRekeningKoran5b5(listKYCDocs.rekening_koran_5b_5);
        } else {
          setRekeningKoran5b5(null);
        }

        if (listKYCDocs.rekening_koran_5b_6 !== undefined) {
          setRekeningKoran5b6(listKYCDocs.rekening_koran_5b_6);
        } else {
          setRekeningKoran5b6(null);
        }

        if (listKYCDocs.rekening_koran_6b !== undefined) {
          setRekeningKoran6b(listKYCDocs.rekening_koran_6b);
        } else {
          setRekeningKoran6b(null);
        }

        if (listKYCDocs.rekening_koran_6b_2 !== undefined) {
          setRekeningKoran6b2(listKYCDocs.rekening_koran_6b_2);
        } else {
          setRekeningKoran6b2(null);
        }

        if (listKYCDocs.rekening_koran_6b_3 !== undefined) {
          setRekeningKoran6b3(listKYCDocs.rekening_koran_6b_3);
        } else {
          setRekeningKoran6b3(null);
        }

        if (listKYCDocs.rekening_koran_6b_4 !== undefined) {
          setRekeningKoran6b4(listKYCDocs.rekening_koran_6b_4);
        } else {
          setRekeningKoran6b4(null);
        }

        if (listKYCDocs.rekening_koran_6b_5 !== undefined) {
          setRekeningKoran6b5(listKYCDocs.rekening_koran_6b_5);
        } else {
          setRekeningKoran6b5(null);
        }

        if (listKYCDocs.rekening_koran_6b_6 !== undefined) {
          setRekeningKoran6b6(listKYCDocs.rekening_koran_6b_6);
        } else {
          setRekeningKoran6b6(null);
        }

        if (listKYCDocs.surat_pernyataan !== undefined) {
          setSuratPernyataan(serverList.company + listKYCDocs.surat_pernyataan.url);
        } else {
          setSuratPernyataan(null);
        }

        setListStatus({
          noHP: listKYCDocs.phone ? transformStatusKyc(listKYCDocs.phone.status) : "",
          ktpFile: listKYCDocs.pic_ktp ? transformStatusKyc(listKYCDocs.pic_ktp.status) : "",
          ktpSelfieFile: listKYCDocs.pic_ktp_selfie
            ? transformStatusKyc(listKYCDocs.pic_ktp_selfie.status)
            : "",
          aktaPendirian: listKYCDocs.akta_pendirian
            ? transformStatusKyc(listKYCDocs.akta_pendirian.status)
            : "",
          aktaPerubahan: listKYCDocs.akta_perubahan
            ? transformStatusKyc(listKYCDocs.akta_perubahan.status)
            : "",
          aktaPendirianSK: listKYCDocs.akta_pendirian_sk
            ? transformStatusKyc(listKYCDocs.akta_pendirian_sk.status)
            : "",
          aktaPerubahanSK: listKYCDocs.akta_perubahan_sk
            ? transformStatusKyc(listKYCDocs.akta_perubahan_sk.status)
            : "",
          siupNib: listKYCDocs.siup_nib ? transformStatusKyc(listKYCDocs.siup_nib.status) : "",
          npwp: listKYCDocs.npwp ? transformStatusKyc(listKYCDocs.npwp.status) : "",
          ktpPengurus: listKYCDocs.pengurus_ktp
            ? transformStatusKyc(listKYCDocs.pengurus_ktp.status)
            : "",
          ktpDireksi: listKYCDocs.direksi_ktp
            ? transformStatusKyc(listKYCDocs.direksi_ktp.status)
            : "",
          ktpKomisaris: listKYCDocs.komisaris_ktp
            ? transformStatusKyc(listKYCDocs.komisaris_ktp.status)
            : "",
          npwpDireksi: listKYCDocs.direksi_npwp
            ? transformStatusKyc(listKYCDocs.direksi_npwp.status)
            : "",
          npwpKomisaris: listKYCDocs.komisaris_npwp
            ? transformStatusKyc(listKYCDocs.komisaris_npwp.status)
            : "",
          laporanKeuangan: listKYCDocs.laporan_keuangan
            ? transformStatusKyc(listKYCDocs.laporan_keuangan.status)
            : "",
          laporanKeuangan1t: listKYCDocs.laporan_keuangan_1t
            ? transformStatusKyc(listKYCDocs.laporan_keuangan_1t.status)
            : "",
          laporanKeuangan2t: listKYCDocs.laporan_keuangan_2t
            ? transformStatusKyc(listKYCDocs.laporan_keuangan_2t.status)
            : "",
          rekeningKoran: listKYCDocs.rekening_koran
            ? transformStatusKyc(listKYCDocs.rekening_koran.status)
            : "",
          rekeningKoran1b: listKYCDocs.rekening_koran_1b
            ? transformStatusKyc(listKYCDocs.rekening_koran_1b.status)
            : "",
          rekeningKoran2b: listKYCDocs.rekening_koran_2b
            ? transformStatusKyc(listKYCDocs.rekening_koran_2b.status)
            : "",
          rekeningKoran3b: listKYCDocs.rekening_koran_3b
            ? transformStatusKyc(listKYCDocs.rekening_koran_3b.status)
            : "",
          rekeningKoran4b: listKYCDocs.rekening_koran_4b
            ? transformStatusKyc(listKYCDocs.rekening_koran_4b.status)
            : "",
          rekeningKoran5b: listKYCDocs.rekening_koran_5b
            ? transformStatusKyc(listKYCDocs.rekening_koran_5b.status)
            : "",
          rekeningKoran6b: listKYCDocs.rekening_koran_6b
            ? transformStatusKyc(listKYCDocs.rekening_koran_6b.status)
            : "",
          suratPernyataan: listKYCDocs.surat_pernyataan
            ? transformStatusKyc(listKYCDocs.surat_pernyataan.status)
            : "",
        });
      } else {
        var completed1 = true;
        var completed2 = true;
        var completed3 = true;
        var completed4 = true;
        var completed5 = true;
        if (listKYCDocs.phone !== undefined) {
          if (listKYCDocs.phone.data !== undefined) {
            setNoHP(listKYCDocs.phone.data);
            if (listKYCDocs.phone.status === "rejected") {
              completed1 = false;
              setRejectNoHP(true);
            } else {
              setRejectNoHP(false);
            }
          } else {
            setNoHP("");
            completed1 = false;
          }
        } else {
          setNoHP("");
          completed1 = false;
        }

        if (listKYCDocs.pic_ktp !== undefined) {
          setKtpFile(serverList.company + listKYCDocs.pic_ktp.url);
          if (listKYCDocs.pic_ktp.status === "rejected") {
            completed2 = false;
          }
        } else {
          setKtpFile(null);
          completed2 = false;
        }

        if (listKYCDocs.pic_ktp_selfie !== undefined) {
          setKtpSelfieFile(serverList.company + listKYCDocs.pic_ktp_selfie.url);
          if (listKYCDocs.pic_ktp_selfie.status === "rejected") {
            completed2 = false;
          }
        } else {
          setKtpSelfieFile(null);
          completed2 = false;
        }

        if (listKYCDocs.akta_pendirian !== undefined) {
          setAktaPendirian(listKYCDocs.akta_pendirian.id);
          if (listKYCDocs.akta_pendirian.status === "rejected") {
            completed3 = false;
          }
        } else {
          setAktaPendirian(null);
          completed3 = false;
        }

        if (listKYCDocs.akta_perubahan !== undefined) {
          setAktaPerubahan(listKYCDocs.akta_perubahan.id);
          if (listKYCDocs.akta_perubahan.status === "rejected") {
            completed3 = false;
          }
        } else {
          setAktaPerubahan(null);
          completed3 = false;
        }

        if (listKYCDocs.siup_nib !== undefined) {
          setSiupNib(listKYCDocs.siup_nib.id);
          if (listKYCDocs.siup_nib.status === "rejected") {
            completed3 = false;
          }
        } else {
          setSiupNib(null);
          completed3 = false;
        }

        if (listKYCDocs.npwp !== undefined) {
          setNpwp(listKYCDocs.npwp.id);
          if (listKYCDocs.npwp.status === "rejected") {
            completed3 = false;
          }
        } else {
          setNpwp(null);
          completed3 = false;
        }

        if (listKYCDocs.pengurus_ktp !== undefined) {
          setKtpPengurus(listKYCDocs.pengurus_ktp.id);
          if (listKYCDocs.pengurus_ktp.status === "rejected") {
            completed4 = false;
          }
        } else {
          setKtpPengurus(null);
          completed4 = false;
        }

        if (listKYCDocs.direksi_ktp !== undefined) {
          setKtpDireksi(listKYCDocs.direksi_ktp.id);
          if (listKYCDocs.direksi_ktp.status === "rejected") {
            completed4 = false;
          }
        } else {
          setKtpDireksi(null);
          completed4 = false;
        }

        if (listKYCDocs.direksi_npwp !== undefined) {
          setNpwpDireksi(listKYCDocs.direksi_npwp.id);
          if (listKYCDocs.direksi_npwp.status === "rejected") {
            completed4 = false;
          }
        } else {
          setNpwpDireksi(null);
          completed4 = false;
        }

        if (listKYCDocs.laporan_keuangan !== undefined) {
          setLaporanKeuangan(listKYCDocs.laporan_keuangan.id);
          if (listKYCDocs.laporan_keuangan.status === "rejected") {
            completed5 = false;
          }
        } else {
          setLaporanKeuangan(null);
          completed5 = false;
        }

        if (listKYCDocs.rekening_koran !== undefined) {
          setRekeningKoran(listKYCDocs.rekening_koran.id);
          if (listKYCDocs.rekening_koran.status === "rejected") {
            completed5 = false;
          }
        } else {
          setRekeningKoran(null);
          completed5 = false;
        }
        if (!completed1) {
          setActiveStep(0);
        } else if (!completed2) {
          setActiveStep(1);
        } else if (!completed3) {
          setActiveStep(2);
        } else if (!completed4) {
          setActiveStep(3);
        } else if (!completed5) {
          setActiveStep(4);
        }
        if (completed1 && completed2 && completed3 && completed4 && completed5) {
          setActiveStep(5);
        }
        setListStatus({
          noHP: listKYCDocs.phone ? transformStatusKyc(listKYCDocs.phone.status) : "",
          ktpFile: listKYCDocs.pic_ktp ? transformStatusKyc(listKYCDocs.pic_ktp.status) : "",
          ktpSelfieFile: listKYCDocs.pic_ktp_selfie
            ? transformStatusKyc(listKYCDocs.pic_ktp_selfie.status)
            : "",
          aktaPendirian: listKYCDocs.akta_pendirian
            ? transformStatusKyc(listKYCDocs.akta_pendirian.status)
            : "",
          aktaPerubahan: listKYCDocs.akta_perubahan
            ? transformStatusKyc(listKYCDocs.akta_perubahan.status)
            : "",
          siupNib: listKYCDocs.siup_nib ? transformStatusKyc(listKYCDocs.siup_nib.status) : "",
          npwp: listKYCDocs.npwp ? transformStatusKyc(listKYCDocs.npwp.status) : "",
          ktpPengurus: listKYCDocs.pengurus_ktp
            ? transformStatusKyc(listKYCDocs.pengurus_ktp.status)
            : "",
          ktpDireksi: listKYCDocs.direksi_ktp
            ? transformStatusKyc(listKYCDocs.direksi_ktp.status)
            : "",
          npwpDireksi: listKYCDocs.direksi_npwp
            ? transformStatusKyc(listKYCDocs.direksi_npwp.status)
            : "",
          laporanKeuangan: listKYCDocs.laporan_keuangan
            ? transformStatusKyc(listKYCDocs.laporan_keuangan.status)
            : "",
          rekeningKoran: listKYCDocs.rekening_koran
            ? transformStatusKyc(listKYCDocs.rekening_koran.status)
            : "",
        });
      }
    }
  }, [listKYCDocs, anCompany, layout]);

  useEffect(() => {
    if (anCompanyKycStatus.status === "approved") {
      setActiveStep(5);
      setStatusStep(2);
    }
  }, [anCompanyKycStatus]);

  useEffect(() => {
    switch (message.status) {
      case "DELETE_KYC_DOCUMENT":
        dispatch(fetchKYCDocs());
        return;
      case "SUCCESS_SAVE_KYC_DOCUMENT":
        setLoading("");
        dispatch(fetchKYCDocs());
      default:
    }
  }, [message]);

  useEffect(() => {
    if (!(validator.isMobilePhone(noHP) || noHP.trim() === "")) {
      setShowError({ ...showError, noHP: true });
    } else {
      setShowError({ ...showError, noHP: false });
    }
  }, [noHP]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onNoHPBlur = () => {};

  const onChangeKTP = (e) => {
    if (listKYCDocs.pic_ktp === undefined) {
      dispatch(saveKYCDocument({ assetName: "pic_ktp", assetFile: e.target.files[0] }));
      if (refs.ktpFile.current !== undefined) {
        refs.ktpFile.current.value = "";
      }
    }
  };

  const onChangeKTPSelfie = (e) => {
    if (listKYCDocs.pic_ktp_selfie === undefined) {
      dispatch(saveKYCDocument({ assetName: "pic_ktp_selfie", assetFile: e.target.files[0] }));
      if (refs.ktpSelfieFile.current !== undefined) {
        refs.ktpSelfieFile.current.value = "";
      }
    }
  };

  const onChangeFotoUsaha = (e) => {
    setLoading("fotoUsaha");
    if (listKYCDocs.foto_usaha === undefined) {
      dispatch(saveKYCDocument({ assetName: "foto_usaha", assetFile: e.target.files[0] }));
      if (refs.fotoUsaha.current !== undefined) {
        refs.fotoUsaha.current.value = "";
      }
    } else if (listKYCDocs.foto_usaha_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "foto_usaha_2", assetFile: e.target.files[0] }));
      if (refs.fotoUsaha_2.current !== undefined) {
        refs.fotoUsaha_2.current.value = "";
      }
    } else if (listKYCDocs.foto_usaha_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "foto_usaha_3", assetFile: e.target.files[0] }));
      if (refs.fotoUsaha_3.current !== undefined) {
        refs.fotoUsaha_3.current.value = "";
      }
    } else if (listKYCDocs.foto_usaha_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "foto_usaha_4", assetFile: e.target.files[0] }));
      if (refs.fotoUsaha_4.current !== undefined) {
        refs.fotoUsaha_4.current.value = "";
      }
    } else if (listKYCDocs.foto_usaha_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "foto_usaha_5", assetFile: e.target.files[0] }));
      if (refs.fotoUsaha_5.current !== undefined) {
        refs.fotoUsaha_5.current.value = "";
      }
    } else if (listKYCDocs.foto_usaha_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "foto_usaha_6", assetFile: e.target.files[0] }));
      if (refs.fotoUsaha_6.current !== undefined) {
        refs.fotoUsaha_6.current.value = "";
      }
    }
  };

  const onChangeAktaPendirian = (e) => {
    setLoading("aktaPendirian");
    if (listKYCDocs.akta_pendirian === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian", assetFile: e.target.files[0] }));
      if (refs.aktaPendirian.current !== undefined) {
        refs.aktaPendirian.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_2", assetFile: e.target.files[0] }));
      if (refs.aktaPendirian_2.current !== undefined) {
        refs.aktaPendirian_2.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_3", assetFile: e.target.files[0] }));
      if (refs.aktaPendirian_3.current !== undefined) {
        refs.aktaPendirian_3.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_4", assetFile: e.target.files[0] }));
      if (refs.aktaPendirian_4.current !== undefined) {
        refs.aktaPendirian_4.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_5", assetFile: e.target.files[0] }));
      if (refs.aktaPendirian_5.current !== undefined) {
        refs.aktaPendirian_5.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_6", assetFile: e.target.files[0] }));
      if (refs.aktaPendirian_6.current !== undefined) {
        refs.aktaPendirian_6.current.value = "";
      }
    }
  };

  const onChangeAktaPendirianSK = (e) => {
    setLoading("aktaPendirianSK");
    if (listKYCDocs.akta_pendirian_sk === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_sk", assetFile: e.target.files[0] }));
      if (refs.aktaPendirianSK.current !== undefined) {
        refs.aktaPendirianSK.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_sk_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_sk_2", assetFile: e.target.files[0] }));
      if (refs.aktaPendirianSK_2.current !== undefined) {
        refs.aktaPendirianSK_2.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_sk_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_sk_3", assetFile: e.target.files[0] }));
      if (refs.aktaPendirianSK_3.current !== undefined) {
        refs.aktaPendirianSK_3.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_sk_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_sk_4", assetFile: e.target.files[0] }));
      if (refs.aktaPendirianSK_4.current !== undefined) {
        refs.aktaPendirianSK_4.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_sk_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_sk_5", assetFile: e.target.files[0] }));
      if (refs.aktaPendirianSK_5.current !== undefined) {
        refs.aktaPendirianSK_5.current.value = "";
      }
    } else if (listKYCDocs.akta_pendirian_sk_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_pendirian_sk_6", assetFile: e.target.files[0] }));
      if (refs.aktaPendirianSK_6.current !== undefined) {
        refs.aktaPendirianSK_6.current.value = "";
      }
    }
  };

  const onChangeAktaPerubahan = (e) => {
    setLoading("aktaPerubahan");
    if (listKYCDocs.akta_perubahan === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahan.current !== undefined) {
        refs.aktaPerubahan.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_2", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahan_2.current !== undefined) {
        refs.aktaPerubahan_2.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_3", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahan_3.current !== undefined) {
        refs.aktaPerubahan_3.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_4", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahan_4.current !== undefined) {
        refs.aktaPerubahan_4.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_5", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahan_5.current !== undefined) {
        refs.aktaPerubahan_5.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_6", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahan_6.current !== undefined) {
        refs.aktaPerubahan_6.current.value = "";
      }
    }
  };

  const onChangeAktaPerubahanSK = (e) => {
    setLoading("aktaPerubahanSK");
    if (listKYCDocs.akta_perubahan_sk === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_sk", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahanSK.current !== undefined) {
        refs.aktaPerubahanSK.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_sk_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_sk_2", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahanSK_2.current !== undefined) {
        refs.aktaPerubahanSK_2.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_sk_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_sk_3", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahanSK_3.current !== undefined) {
        refs.aktaPerubahanSK_3.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_sk_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_sk_4", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahanSK_4.current !== undefined) {
        refs.aktaPerubahanSK_4.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_sk_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_sk_5", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahanSK_5.current !== undefined) {
        refs.aktaPerubahanSK_5.current.value = "";
      }
    } else if (listKYCDocs.akta_perubahan_sk_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "akta_perubahan_sk_6", assetFile: e.target.files[0] }));
      if (refs.aktaPerubahanSK_6.current !== undefined) {
        refs.aktaPerubahanSK_6.current.value = "";
      }
    }
  };

  const onChangeSiupNib = (e) => {
    setLoading("siupNib");
    if (listKYCDocs.siup_nib === undefined) {
      dispatch(saveKYCDocument({ assetName: "siup_nib", assetFile: e.target.files[0] }));
      if (refs.siupNib.current !== undefined) {
        refs.siupNib.current.value = "";
      }
    } else if (listKYCDocs.siup_nib_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "siup_nib_2", assetFile: e.target.files[0] }));
      if (refs.siupNib_2.current !== undefined) {
        refs.siupNib_2.current.value = "";
      }
    } else if (listKYCDocs.siup_nib_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "siup_nib_3", assetFile: e.target.files[0] }));
      if (refs.siupNib_3.current !== undefined) {
        refs.siupNib_3.current.value = "";
      }
    } else if (listKYCDocs.siup_nib_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "siup_nib_4", assetFile: e.target.files[0] }));
      if (refs.siupNib_4.current !== undefined) {
        refs.siupNib_4.current.value = "";
      }
    } else if (listKYCDocs.siup_nib_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "siup_nib_5", assetFile: e.target.files[0] }));
      if (refs.siupNib_5.current !== undefined) {
        refs.siupNib_5.current.value = "";
      }
    } else if (listKYCDocs.siup_nib_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "siup_nib_6", assetFile: e.target.files[0] }));
      if (refs.siupNib_6.current !== undefined) {
        refs.siupNib_6.current.value = "";
      }
    }
  };

  const onChangeNpwp = (e) => {
    setLoading("npwp");
    if (listKYCDocs.npwp === undefined) {
      dispatch(saveKYCDocument({ assetName: "npwp", assetFile: e.target.files[0] }));
      if (refs.npwp.current !== undefined) {
        refs.npwp.current.value = "";
      }
    } else if (listKYCDocs.npwp_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "npwp_2", assetFile: e.target.files[0] }));
      if (refs.npwp_2.current !== undefined) {
        refs.npwp_2.current.value = "";
      }
    } else if (listKYCDocs.npwp_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "npwp_3", assetFile: e.target.files[0] }));
      if (refs.npwp_3.current !== undefined) {
        refs.npwp_3.current.value = "";
      }
    } else if (listKYCDocs.npwp_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "npwp_4", assetFile: e.target.files[0] }));
      if (refs.npwp_4.current !== undefined) {
        refs.npwp_4.current.value = "";
      }
    } else if (listKYCDocs.npwp_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "npwp_5", assetFile: e.target.files[0] }));
      if (refs.npwp_5.current !== undefined) {
        refs.npwp_5.current.value = "";
      }
    } else if (listKYCDocs.npwp_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "npwp_6", assetFile: e.target.files[0] }));
      if (refs.npwp_6.current !== undefined) {
        refs.npwp_6.current.value = "";
      }
    }
  };

  const onChangeKtpPengurus = (e) => {
    setLoading("ktpPengurus");
    if (listKYCDocs.pengurus_ktp === undefined) {
      dispatch(saveKYCDocument({ assetName: "pengurus_ktp", assetFile: e.target.files[0] }));
      if (refs.ktpPengurus.current !== undefined) {
        refs.ktpPengurus.current.value = "";
      }
    } else if (listKYCDocs.pengurus_ktp_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "pengurus_ktp_2", assetFile: e.target.files[0] }));
      if (refs.ktpPengurus_2.current !== undefined) {
        refs.ktpPengurus_2.current.value = "";
      }
    } else if (listKYCDocs.pengurus_ktp_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "pengurus_ktp_3", assetFile: e.target.files[0] }));
      if (refs.ktpPengurus_3.current !== undefined) {
        refs.ktpPengurus_3.current.value = "";
      }
    } else if (listKYCDocs.pengurus_ktp_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "pengurus_ktp_4", assetFile: e.target.files[0] }));
      if (refs.ktpPengurus_4.current !== undefined) {
        refs.ktpPengurus_4.current.value = "";
      }
    } else if (listKYCDocs.pengurus_ktp_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "pengurus_ktp_5", assetFile: e.target.files[0] }));
      if (refs.ktpPengurus_5.current !== undefined) {
        refs.ktpPengurus_5.current.value = "";
      }
    } else if (listKYCDocs.pengurus_ktp_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "pengurus_ktp_6", assetFile: e.target.files[0] }));
      if (refs.ktpPengurus_6.current !== undefined) {
        refs.ktpPengurus_6.current.value = "";
      }
    }
  };

  const onChangeKtpDireksi = (e) => {
    setLoading("ktpDireksi");
    if (listKYCDocs.direksi_ktp === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_ktp", assetFile: e.target.files[0] }));
      if (refs.ktpDireksi.current !== undefined) {
        refs.ktpDireksi.current.value = "";
      }
    } else if (listKYCDocs.direksi_ktp_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_ktp_2", assetFile: e.target.files[0] }));
      if (refs.ktpDireksi_2.current !== undefined) {
        refs.ktpDireksi_2.current.value = "";
      }
    } else if (listKYCDocs.direksi_ktp_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_ktp_3", assetFile: e.target.files[0] }));
      if (refs.ktpDireksi_3.current !== undefined) {
        refs.ktpDireksi_3.current.value = "";
      }
    } else if (listKYCDocs.direksi_ktp_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_ktp_4", assetFile: e.target.files[0] }));
      if (refs.ktpDireksi_4.current !== undefined) {
        refs.ktpDireksi_4.current.value = "";
      }
    } else if (listKYCDocs.direksi_ktp_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_ktp_5", assetFile: e.target.files[0] }));
      if (refs.ktpDireksi_5.current !== undefined) {
        refs.ktpDireksi_5.current.value = "";
      }
    } else if (listKYCDocs.direksi_ktp_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_ktp_6", assetFile: e.target.files[0] }));
      if (refs.ktpDireksi_6.current !== undefined) {
        refs.ktpDireksi_6.current.value = "";
      }
    }
  };

  const onChangeKtpKomisaris = (e) => {
    setLoading("ktpKomisaris");
    if (listKYCDocs.komisaris_ktp === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_ktp", assetFile: e.target.files[0] }));
      if (refs.ktpKomisaris.current !== undefined) {
        refs.ktpKomisaris.current.value = "";
      }
    } else if (listKYCDocs.komisaris_ktp_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_ktp_2", assetFile: e.target.files[0] }));
      if (refs.ktpKomisaris_2.current !== undefined) {
        refs.ktpKomisaris_2.current.value = "";
      }
    } else if (listKYCDocs.komisaris_ktp_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_ktp_3", assetFile: e.target.files[0] }));
      if (refs.ktpKomisaris_3.current !== undefined) {
        refs.ktpKomisaris_3.current.value = "";
      }
    } else if (listKYCDocs.komisaris_ktp_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_ktp_4", assetFile: e.target.files[0] }));
      if (refs.ktpKomisaris_4.current !== undefined) {
        refs.ktpKomisaris_4.current.value = "";
      }
    } else if (listKYCDocs.komisaris_ktp_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_ktp_5", assetFile: e.target.files[0] }));
      if (refs.ktpKomisaris_5.current !== undefined) {
        refs.ktpKomisaris_5.current.value = "";
      }
    } else if (listKYCDocs.komisaris_ktp_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_ktp_6", assetFile: e.target.files[0] }));
      if (refs.ktpKomisaris_6.current !== undefined) {
        refs.ktpKomisaris_6.current.value = "";
      }
    }
  };

  const onChangeNpwpDireksi = (e) => {
    setLoading("npwpDireksi");
    if (listKYCDocs.direksi_npwp === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_npwp", assetFile: e.target.files[0] }));
      if (refs.npwpDireksi.current !== undefined) {
        refs.npwpDireksi.current.value = "";
      }
    } else if (listKYCDocs.direksi_npwp_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_npwp_2", assetFile: e.target.files[0] }));
      if (refs.npwpDireksi_2.current !== undefined) {
        refs.npwpDireksi_2.current.value = "";
      }
    } else if (listKYCDocs.direksi_npwp_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_npwp_3", assetFile: e.target.files[0] }));
      if (refs.npwpDireksi_3.current !== undefined) {
        refs.npwpDireksi_3.current.value = "";
      }
    } else if (listKYCDocs.direksi_npwp_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_npwp_4", assetFile: e.target.files[0] }));
      if (refs.npwpDireksi_4.current !== undefined) {
        refs.npwpDireksi_4.current.value = "";
      }
    } else if (listKYCDocs.direksi_npwp_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_npwp_5", assetFile: e.target.files[0] }));
      if (refs.npwpDireksi_5.current !== undefined) {
        refs.npwpDireksi_5.current.value = "";
      }
    } else if (listKYCDocs.direksi_npwp_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "direksi_npwp_6", assetFile: e.target.files[0] }));
      if (refs.npwpDireksi_6.current !== undefined) {
        refs.npwpDireksi_6.current.value = "";
      }
    }
  };

  const onChangeNpwpKomisaris = (e) => {
    setLoading("npwpKomisaris");
    if (listKYCDocs.komisaris_npwp === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_npwp", assetFile: e.target.files[0] }));
      if (refs.npwpKomisaris.current !== undefined) {
        refs.npwpKomisaris.current.value = "";
      }
    } else if (listKYCDocs.komisaris_npwp_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_npwp_2", assetFile: e.target.files[0] }));
      if (refs.npwpKomisaris_2.current !== undefined) {
        refs.npwpKomisaris_2.current.value = "";
      }
    } else if (listKYCDocs.komisaris_npwp_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_npwp_3", assetFile: e.target.files[0] }));
      if (refs.npwpKomisaris_3.current !== undefined) {
        refs.npwpKomisaris_3.current.value = "";
      }
    } else if (listKYCDocs.komisaris_npwp_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_npwp_4", assetFile: e.target.files[0] }));
      if (refs.npwpKomisaris_4.current !== undefined) {
        refs.npwpKomisaris_4.current.value = "";
      }
    } else if (listKYCDocs.komisaris_npwp_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_npwp_5", assetFile: e.target.files[0] }));
      if (refs.npwpKomisaris_5.current !== undefined) {
        refs.npwpKomisaris_5.current.value = "";
      }
    } else if (listKYCDocs.komisaris_npwp_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "komisaris_npwp_6", assetFile: e.target.files[0] }));
      if (refs.npwpKomisaris_6.current !== undefined) {
        refs.npwpKomisaris_6.current.value = "";
      }
    }
  };

  const onChangeLaporanKeuangan = (e) => {
    setLoading("laporanKeuangan");
    if (listKYCDocs.laporan_keuangan === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan.current !== undefined) {
        refs.laporanKeuangan.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_2", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan_2.current !== undefined) {
        refs.laporanKeuangan_2.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_3", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan_3.current !== undefined) {
        refs.laporanKeuangan_3.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_4", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan_4.current !== undefined) {
        refs.laporanKeuangan_4.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_5", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan_5.current !== undefined) {
        refs.laporanKeuangan_5.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_6", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan_6.current !== undefined) {
        refs.laporanKeuangan_6.current.value = "";
      }
    }
  };

  const onChangeLaporanKeuangan1t = (e) => {
    setLoading("laporanKeuangan1t");
    if (listKYCDocs.laporan_keuangan_1t === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_1t", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan1t.current !== undefined) {
        refs.laporanKeuangan1t.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_1t_2 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_1t_2", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan1t_2.current !== undefined) {
        refs.laporanKeuangan1t_2.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_1t_3 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_1t_3", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan1t_3.current !== undefined) {
        refs.laporanKeuangan1t_3.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_1t_4 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_1t_4", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan1t_4.current !== undefined) {
        refs.laporanKeuangan1t_4.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_1t_5 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_1t_5", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan1t_5.current !== undefined) {
        refs.laporanKeuangan1t_5.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_1t_6 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_1t_6", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan1t_6.current !== undefined) {
        refs.laporanKeuangan1t_6.current.value = "";
      }
    }
  };

  const onChangeLaporanKeuangan2t = (e) => {
    setLoading("laporanKeuangan2t");
    if (listKYCDocs.laporan_keuangan_2t === undefined) {
      dispatch(saveKYCDocument({ assetName: "laporan_keuangan_2t", assetFile: e.target.files[0] }));
      if (refs.laporanKeuangan2t.current !== undefined) {
        refs.laporanKeuangan2t.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_2t_2 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_2t_2", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan2t_2.current !== undefined) {
        refs.laporanKeuangan2t_2.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_2t_3 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_2t_3", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan2t_3.current !== undefined) {
        refs.laporanKeuangan2t_3.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_2t_4 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_2t_4", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan2t_4.current !== undefined) {
        refs.laporanKeuangan2t_4.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_2t_5 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_2t_5", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan2t_5.current !== undefined) {
        refs.laporanKeuangan2t_5.current.value = "";
      }
    } else if (listKYCDocs.laporan_keuangan_2t_6 === undefined) {
      dispatch(
        saveKYCDocument({ assetName: "laporan_keuangan_2t_6", assetFile: e.target.files[0] })
      );
      if (refs.laporanKeuangan2t_6.current !== undefined) {
        refs.laporanKeuangan2t_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran = (e) => {
    setLoading("rekeningKoran");
    if (listKYCDocs.rekening_koran === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran.current !== undefined) {
        refs.rekeningKoran.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran_2.current !== undefined) {
        refs.rekeningKoran_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran_3.current !== undefined) {
        refs.rekeningKoran_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran_4.current !== undefined) {
        refs.rekeningKoran_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran_5.current !== undefined) {
        refs.rekeningKoran_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran_6.current !== undefined) {
        refs.rekeningKoran_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran1b = (e) => {
    setLoading("rekeningKoran1b");
    if (listKYCDocs.rekening_koran_1b === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_1b", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran1b.current !== undefined) {
        refs.rekeningKoran1b.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_1b_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_1b_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran1b_2.current !== undefined) {
        refs.rekeningKoran1b_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_1b_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_1b_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran1b_3.current !== undefined) {
        refs.rekeningKoran1b_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_1b_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_1b_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran1b_4.current !== undefined) {
        refs.rekeningKoran1b_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_1b_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_1b_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran1b_5.current !== undefined) {
        refs.rekeningKoran1b_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_1b_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_1b_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran1b_6.current !== undefined) {
        refs.rekeningKoran1b_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran2b = (e) => {
    setLoading("rekeningKoran2b");
    if (listKYCDocs.rekening_koran_2b === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2b", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran2b.current !== undefined) {
        refs.rekeningKoran2b.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_2b_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2b_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran2b_2.current !== undefined) {
        refs.rekeningKoran2b_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_2b_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2b_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran2b_3.current !== undefined) {
        refs.rekeningKoran2b_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_2b_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2b_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran2b_4.current !== undefined) {
        refs.rekeningKoran2b_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_2b_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2b_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran2b_5.current !== undefined) {
        refs.rekeningKoran2b_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_2b_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_2b_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran2b_6.current !== undefined) {
        refs.rekeningKoran2b_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran3b = (e) => {
    setLoading("rekeningKoran3b");
    if (listKYCDocs.rekening_koran_3b === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3b", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran3b.current !== undefined) {
        refs.rekeningKoran3b.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_3b_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3b_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran3b_2.current !== undefined) {
        refs.rekeningKoran3b_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_3b_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3b_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran3b_3.current !== undefined) {
        refs.rekeningKoran3b_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_3b_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3b_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran3b_4.current !== undefined) {
        refs.rekeningKoran3b_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_3b_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3b_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran3b_5.current !== undefined) {
        refs.rekeningKoran3b_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_3b_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_3b_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran3b_6.current !== undefined) {
        refs.rekeningKoran3b_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran4b = (e) => {
    setLoading("rekeningKoran4b");
    if (listKYCDocs.rekening_koran_4b === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4b", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran4b.current !== undefined) {
        refs.rekeningKoran4b.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_4b_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4b_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran4b_2.current !== undefined) {
        refs.rekeningKoran4b_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_4b_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4b_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran4b_3.current !== undefined) {
        refs.rekeningKoran4b_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_4b_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4b_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran4b_4.current !== undefined) {
        refs.rekeningKoran4b_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_4b_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4b_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran4b_5.current !== undefined) {
        refs.rekeningKoran4b_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_4b_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_4b_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran4b_6.current !== undefined) {
        refs.rekeningKoran4b_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran5b = (e) => {
    setLoading("rekeningKoran5b");
    if (listKYCDocs.rekening_koran_5b === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5b", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran5b.current !== undefined) {
        refs.rekeningKoran5b.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_5b_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5b_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran5b_2.current !== undefined) {
        refs.rekeningKoran5b_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_5b_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5b_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran5b_3.current !== undefined) {
        refs.rekeningKoran5b_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_5b_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5b_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran5b_4.current !== undefined) {
        refs.rekeningKoran5b_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_5b_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5b_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran5b_5.current !== undefined) {
        refs.rekeningKoran5b_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_5b_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_5b_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran5b_6.current !== undefined) {
        refs.rekeningKoran5b_6.current.value = "";
      }
    }
  };

  const onChangeRekeningKoran6b = (e) => {
    setLoading("rekeningKoran6b");
    if (listKYCDocs.rekening_koran_6b === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6b", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran6b.current !== undefined) {
        refs.rekeningKoran6b.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_6b_2 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6b_2", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran6b_2.current !== undefined) {
        refs.rekeningKoran6b_2.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_6b_3 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6b_3", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran6b_3.current !== undefined) {
        refs.rekeningKoran6b_3.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_6b_4 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6b_4", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran6b_4.current !== undefined) {
        refs.rekeningKoran6b_4.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_6b_5 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6b_5", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran6b_5.current !== undefined) {
        refs.rekeningKoran6b_5.current.value = "";
      }
    } else if (listKYCDocs.rekening_koran_6b_6 === undefined) {
      dispatch(saveKYCDocument({ assetName: "rekening_koran_6b_6", assetFile: e.target.files[0] }));
      if (refs.rekeningKoran6b_6.current !== undefined) {
        refs.rekeningKoran6b_6.current.value = "";
      }
    }
  };

  const onChangeSuratPernyataan = (e) => {
    if (listKYCDocs.surat_pernyataan === undefined) {
      dispatch(saveKYCDocument({ assetName: "surat_pernyataan", assetFile: e.target.files[0] }));
      if (refs.suratPernyataan.current !== undefined) {
        refs.suratPernyataan.current.value = "";
      }
    }
  };

  const handleDownload = () => {
    const filePath = "/file-download/filetmp.pdf";
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = blobURL;
        a.download = "surat_pernyataan.pdf"; // Set desired file name
        a.style.display = "none";

        document.body.appendChild(a);

        a.click();

        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  const onClickNoHp = () => {
    if (validator.isMobilePhone(noHP)) {
      if (listKYCDocs.phone) {
        if (noHP !== listKYCDocs.phone.data) {
          dispatch(saveKYCPhone(noHP));
        }
        handleNext();
        setShowError({ ...showError, noHP: false });
      } else {
        dispatch(saveKYCPhone(noHP));
        handleNext();
        setShowError({ ...showError, noHP: false });
      }
    } else {
      setShowError({ ...showError, noHP: true });
    }
  };

  const onChangeNoHP = (e) => {
    if (listKYCDocs.phone) {
      if (listKYCDocs.phone.status === "rejected") {
        if (e.target.value === listKYCDocs.phone.data) {
          setRejectNoHP(true);
        } else {
          setRejectNoHP(false);
        }
      }
    }
    setNoHP(e.target.value);
  };

  const onCloseVerifyHP = () => {
    setOpenOTPModal(false);
    handleNext();
  };

  const deleteAssetKYCDocs = (kycId) => {
    dispatch(deleteKYCDocument(kycId));
  };

  const onReturn = () => {
    if (anCompany.type !== undefined) {
      return (
        <>
          {layout === "datadiri" ? (
            <>
              <MDBox>
                <Grid container direction="column" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <MDBox display="flex" justifyContent="end" mt={4}>
                      <MDBox>
                        <MDInput
                          id="noHP"
                          name="noHP"
                          label="Nomor Handphone"
                          placeholder="Isi dengan nomor handphone aktif anda"
                          onChange={onChangeNoHP}
                          value={noHP}
                          onBlur={onNoHPBlur}
                          sx={{ width: 300 }}
                          error={showError.noHP}
                        />
                        <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                          {showError.noHP ? helperMsg.noHP : ""}{" "}
                        </MDTypography>
                        <OTPModal
                          open={openOTPModal}
                          onClose={onCloseVerifyHP}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        />
                      </MDBox>
                      <MDBox ml={2}>
                        <MDButton variant="outlined" onClick={onClickNoHp} color="info">
                          SIMPAN
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid container direction="column" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <LVFileInput
                          srcImage={ktpFile}
                          height={200}
                          width={240}
                          fontSize={10}
                          id="file_ktp"
                          onChangeFile={onChangeKTP}
                          text1="Unggah Foto KTP"
                          text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                          text3=""
                          title="Foto KTP"
                          titleFontSize={12}
                          subtitle={
                            listStatus.ktpFile +
                            (listKYCDocs.pic_ktp
                              ? listKYCDocs.pic_ktp.reason
                                ? ` - ${listKYCDocs.pic_ktp.reason}`
                                : ""
                              : "")
                          }
                          subtitleFontSize={12}
                          accept=".png,.jpg,.pdf"
                          deleteFile={() => deleteAssetKYCDocs(listKYCDocs.pic_ktp.id)}
                          refinput={refs.ktpFile}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          ) : layout === "legalitas" ? (
            <MDBox mt={2}>
              <LVFileInputMultipleDisplay
                srcImage={[fotoUsaha, fotoUsaha2, fotoUsaha3, fotoUsaha4, fotoUsaha5, fotoUsaha6]}
                onChangeFile={onChangeFotoUsaha}
                isLoading={isLoading === "fotoUsaha"}
              />
              <Grid container direction="row" justifyContent="space-around" spacing={1}>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      aktaPendirian,
                      aktaPendirian2,
                      aktaPendirian3,
                      aktaPendirian4,
                      aktaPendirian5,
                      aktaPendirian6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="akta_pendirian"
                    onChangeFile={onChangeAktaPendirian}
                    text1="Unggah Akta Pendirian"
                    text2="Maksimal ukuran 5MB PDF"
                    text3=""
                    title="Akta Pendirian"
                    titleFontSize={12}
                    subtitle={
                      listStatus.aktaPendirian +
                      (listKYCDocs.akta_pendirian
                        ? listKYCDocs.akta_pendirian.reason
                          ? ` - ${listKYCDocs.akta_pendirian.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.akta_pendirian.id)}
                    refinput={refs.aktaPendirian}
                    isLoading={isLoading === "aktaPendirian"}
                  />
                </Grid>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      aktaPendirianSK,
                      aktaPendirianSK2,
                      aktaPendirianSK3,
                      aktaPendirianSK4,
                      aktaPendirianSK5,
                      aktaPendirianSK6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="akta_pendirian_sk"
                    onChangeFile={onChangeAktaPendirianSK}
                    text1="Unggah SK Kemenkumham Akta Pendirian"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="SK Kemenkumham Akta Pendirian"
                    titleFontSize={12}
                    subtitle={
                      listStatus.aktaPendirianSK +
                      (listKYCDocs.akta_pendirian_sk
                        ? listKYCDocs.akta_pendirian_sk.reason
                          ? ` - ${listKYCDocs.akta_pendirian_sk.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.akta_pendirian_sk.id)}
                    refinput={refs.aktaPendirianSK}
                    isLoading={isLoading === "aktaPendirianSk"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" spacing={1}>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      aktaPerubahan,
                      aktaPerubahan2,
                      aktaPerubahan3,
                      aktaPerubahan4,
                      aktaPerubahan5,
                      aktaPerubahan6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="akta_perubahan"
                    onChangeFile={onChangeAktaPerubahan}
                    text1="Unggah Akta Perubahan"
                    text2="Maksimal ukuran 5MB PDF"
                    text3=""
                    title="Akta Perubahan"
                    titleFontSize={12}
                    subtitle={
                      listStatus.aktaPerubahan +
                      (listKYCDocs.akta_perubahan
                        ? listKYCDocs.akta_perubahan.reason
                          ? ` - ${listKYCDocs.akta_perubahan.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.akta_perubahan.id)}
                    refinput={refs.aktaPerubahan}
                    isLoading={isLoading === "aktaPerubahan"}
                  />
                </Grid>

                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      aktaPerubahanSK,
                      aktaPerubahanSK2,
                      aktaPerubahanSK3,
                      aktaPerubahanSK4,
                      aktaPerubahanSK5,
                      aktaPerubahanSK6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="akta_perubahan_sk"
                    onChangeFile={onChangeAktaPerubahanSK}
                    text1="Unggah SK Kemenkumham Akta Perubahan"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="SK Kemenkumham Akta Perubahan"
                    titleFontSize={12}
                    subtitle={
                      listStatus.aktaPerubahanSK +
                      (listKYCDocs.akta_perubahan_sk
                        ? listKYCDocs.akta_perubahan_sk.reason
                          ? ` - ${listKYCDocs.akta_perubahan_sk.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.akta_perubahan_sk.id)}
                    refinput={refs.aktaPerubahanSK}
                    isLoading={isLoading === "aktaPerubahanSK"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" spacing={1}>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[siupNib, siupNib2, siupNib3, siupNib4, siupNib5, siupNib6]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="siup_nib"
                    onChangeFile={onChangeSiupNib}
                    text1="Unggah SIUP dan TDP atau NIB"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="SIUP dan TDP atau NIB"
                    titleFontSize={12}
                    subtitle={
                      listStatus.siupNib +
                      (listKYCDocs.siup_nib
                        ? listKYCDocs.siup_nib.reason
                          ? ` - ${listKYCDocs.siup_nib.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".pdf, .png,.jpg"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.siup_nib.id)}
                    refinput={refs.siupNib}
                    isLoading={isLoading === "siupNib"}
                  />
                </Grid>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[npwp, npwp2, npwp3, npwp4, npwp5, npwp6]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="npwp"
                    onChangeFile={onChangeNpwp}
                    text1="Unggah NPWP"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="NPWP"
                    titleFontSize={12}
                    subtitle={
                      listStatus.npwp +
                      (listKYCDocs.npwp
                        ? listKYCDocs.npwp.reason
                          ? ` - ${listKYCDocs.npwp.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".pdf, .png,.jpg"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.npwp.id)}
                    refinput={refs.npwp}
                    isLoading={isLoading === "npwp"}
                  />
                </Grid>
              </Grid>
            </MDBox>
          ) : layout === "identitas" ? (
            <MDBox>
              <Grid container direction="row" justifyContent="space-around" spacing={1}>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      ktpPengurus,
                      ktpPengurus2,
                      ktpPengurus3,
                      ktpPengurus4,
                      ktpPengurus5,
                      ktpPengurus6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="ktp_pengurus"
                    onChangeFile={onChangeKtpPengurus}
                    text1="Unggah Foto KTP Pengurus"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="KTP Pengurus"
                    titleFontSize={12}
                    subtitle={
                      listStatus.ktpPengurus +
                      (listKYCDocs.pengurus_ktp
                        ? listKYCDocs.pengurus_ktp.reason
                          ? ` - ${listKYCDocs.pengurus_ktp.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.pengurus_ktp.id)}
                    refinput={refs.ktpPengurus}
                    isLoading={isLoading === "ktpPengurus"}
                  />
                </Grid>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      ktpDireksi,
                      ktpDireksi2,
                      ktpDireksi3,
                      ktpDireksi4,
                      ktpDireksi5,
                      ktpDireksi6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="ktp_direksi"
                    onChangeFile={onChangeKtpDireksi}
                    text1="Unggah Foto KTP Direksi"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="KTP Direksi"
                    titleFontSize={12}
                    subtitle={
                      listStatus.ktpDireksi +
                      (listKYCDocs.direksi_ktp
                        ? listKYCDocs.direksi_ktp.reason
                          ? ` - ${listKYCDocs.direksi_ktp.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.direksi_ktp.id)}
                    refinput={refs.ktpDireksi}
                    isLoading={isLoading === "ktpDireksi"}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" spacing={1}>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[
                      npwpDireksi,
                      npwpDireksi2,
                      npwpDireksi3,
                      npwpDireksi4,
                      npwpDireksi5,
                      npwpDireksi6,
                    ]}
                    height={200}
                    width={240}
                    fontSize={10}
                    id="npwp_direksi"
                    onChangeFile={onChangeNpwpDireksi}
                    text1="Unggah Foto NPWP Direksi"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="NPWP Direksi"
                    titleFontSize={12}
                    subtitle={
                      listStatus.npwpDireksi +
                      (listKYCDocs.direksi_npwp
                        ? listKYCDocs.direksi_npwp.reason
                          ? ` - ${listKYCDocs.direksi_npwp.reason}`
                          : ""
                        : "")
                    }
                    subtitleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    deleteFile={() => deleteAssetKYCDocs(listKYCDocs.direksi_npwp.id)}
                    refinput={refs.npwpDireksi}
                    isLoading={isLoading === "npwpDireksi"}
                  />
                </Grid>
                {anCompany.type !== "PO" ? (
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        ktpKomisaris,
                        ktpKomisaris2,
                        ktpKomisaris3,
                        ktpKomisaris4,
                        ktpKomisaris5,
                        ktpKomisaris6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="ktp_komisaris"
                      onChangeFile={onChangeKtpKomisaris}
                      text1="Unggah Foto KTP Komisaris"
                      text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                      text3=""
                      title="KTP Komisaris"
                      titleFontSize={12}
                      subtitle={
                        listStatus.ktpKomisaris +
                        (listKYCDocs.komisaris_ktp
                          ? listKYCDocs.komisaris_ktp.reason
                            ? ` - ${listKYCDocs.komisaris_ktp.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".png,.jpg,.pdf"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.komisaris_ktp.id)}
                      refinput={refs.ktpKomisaris}
                      isLoading={isLoading === "ktpKomisaris"}
                    />
                  </Grid>
                ) : (
                  <Grid item>
                    <MDBox height={200} width={300} />
                  </Grid>
                )}
              </Grid>
              {anCompany.type !== "PO" ? (
                <Grid container direction="row" justifyContent="start" spacing={1}>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        npwpKomisaris,
                        npwpKomisaris2,
                        npwpKomisaris3,
                        npwpKomisaris4,
                        npwpKomisaris5,
                        npwpKomisaris6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="npwp_komisaris"
                      onChangeFile={onChangeNpwpKomisaris}
                      text1="Unggah Foto NPWP Komisaris"
                      text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                      text3=""
                      title="NPWP Komisaris"
                      titleFontSize={12}
                      subtitle={
                        listStatus.npwpKomisaris +
                        (listKYCDocs.komisaris_npwp
                          ? listKYCDocs.komisaris_npwp.reason
                            ? ` - ${listKYCDocs.komisaris_npwp.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".png,.jpg,.pdf"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.komisaris_npwp.id)}
                      refinput={refs.npwpKomisaris}
                      isLoading={isLoading === "npwpKomisaris"}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </MDBox>
          ) : layout === "kinerja" ? (
            <>
              <MDBox>
                <Grid container direction="row" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        laporanKeuangan,
                        laporanKeuangan2,
                        laporanKeuangan3,
                        laporanKeuangan4,
                        laporanKeuangan5,
                        laporanKeuangan6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="laporan_keuangan"
                      onChangeFile={onChangeLaporanKeuangan}
                      text1="Unggah Laporan Keuangan Tahun Berjalan"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Laporan Keuangan Tahun Berjalan"
                      titleFontSize={12}
                      subtitle={
                        listStatus.laporanKeuangan +
                        (listKYCDocs.laporan_keuangan
                          ? listKYCDocs.laporan_keuangan.reason
                            ? ` - ${listKYCDocs.laporan_keuangan.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.laporan_keuangan.id)}
                      refinput={refs.laporanKeuangan}
                      isLoading={isLoading === "laporanKeuangan"}
                    />
                  </Grid>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        laporanKeuangan1t,
                        laporanKeuangan1t2,
                        laporanKeuangan1t3,
                        laporanKeuangan1t4,
                        laporanKeuangan1t5,
                        laporanKeuangan1t6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="laporan_keuangan_1t"
                      onChangeFile={onChangeLaporanKeuangan1t}
                      text1="Unggah Laporan Keuangan 1 Tahun Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Laporan Keuangan 1 Tahun Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.laporanKeuangan1t +
                        (listKYCDocs.laporan_keuangan_1t
                          ? listKYCDocs.laporan_keuangan_1t.reason
                            ? ` - ${listKYCDocs.laporan_keuangan_1t.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.laporan_keuangan_1t.id)}
                      refinput={refs.laporanKeuangan1t}
                      isLoading={isLoading === "laporanKeuangan1t"}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        laporanKeuangan2t,
                        laporanKeuangan2t2,
                        laporanKeuangan2t3,
                        laporanKeuangan2t4,
                        laporanKeuangan2t5,
                        laporanKeuangan2t6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="laporan_keuangan_2t"
                      onChangeFile={onChangeLaporanKeuangan2t}
                      text1="Unggah Laporan Keuangan 2 Tahun Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Laporan Keuangan 2 Tahun Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.laporanKeuangan2t +
                        (listKYCDocs.laporan_keuangan_2t
                          ? listKYCDocs.laporan_keuangan_2t.reason
                            ? ` - ${listKYCDocs.laporan_keuangan_2t.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.laporan_keuangan_2t.id)}
                      refinput={refs.laporanKeuangan2t}
                      isLoading={isLoading === "laporanKeuangan2t"}
                    />
                  </Grid>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran,
                        rekeningKoran2,
                        rekeningKoran3,
                        rekeningKoran4,
                        rekeningKoran5,
                        rekeningKoran6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran"
                      onChangeFile={onChangeRekeningKoran}
                      text1="Unggah Rekening Koran Bulan Berjalan"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran Bulan Berjalan"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran +
                        (listKYCDocs.rekening_koran
                          ? listKYCDocs.rekening_koran.reason
                            ? ` - ${listKYCDocs.rekening_koran.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran.id)}
                      refinput={refs.rekeningKoran}
                      isLoading={isLoading === "rekeningKoran"}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran1b,
                        rekeningKoran1b2,
                        rekeningKoran1b3,
                        rekeningKoran1b4,
                        rekeningKoran1b5,
                        rekeningKoran1b6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran_1b"
                      onChangeFile={onChangeRekeningKoran1b}
                      text1="Unggah Rekening Koran 1 Bulan Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran 1 Bulan Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran1b +
                        (listKYCDocs.rekening_koran_1b
                          ? listKYCDocs.rekening_koran_1b.reason
                            ? ` - ${listKYCDocs.rekening_koran_1b.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran_1b.id)}
                      refinput={refs.rekeningKoran1b}
                      isLoading={isLoading === "rekeningKoran1b"}
                    />
                  </Grid>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran2b,
                        rekeningKoran2b2,
                        rekeningKoran2b3,
                        rekeningKoran2b4,
                        rekeningKoran2b5,
                        rekeningKoran2b6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran_2b"
                      onChangeFile={onChangeRekeningKoran2b}
                      text1="Unggah Rekening Koran 2 Bulan Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran 2 Bulan Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran2b +
                        (listKYCDocs.rekening_koran_2b
                          ? listKYCDocs.rekening_koran_2b.reason
                            ? ` - ${listKYCDocs.rekening_koran_2b.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran_2b.id)}
                      refinput={refs.rekeningKoran2b}
                      isLoading={isLoading === "rekeningKoran2b"}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran3b,
                        rekeningKoran3b2,
                        rekeningKoran3b3,
                        rekeningKoran3b4,
                        rekeningKoran3b5,
                        rekeningKoran3b6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran_3b"
                      onChangeFile={onChangeRekeningKoran3b}
                      text1="Unggah Rekening Koran 3 Bulan Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran 3 Bulan Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran3b +
                        (listKYCDocs.rekening_koran_3b
                          ? listKYCDocs.rekening_koran_3b.reason
                            ? ` - ${listKYCDocs.rekening_koran_3b.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran_3b.id)}
                      refinput={refs.rekeningKoran3b}
                      isLoading={isLoading === "rekeningKoran3b"}
                    />
                  </Grid>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran4b,
                        rekeningKoran4b2,
                        rekeningKoran4b3,
                        rekeningKoran4b4,
                        rekeningKoran4b5,
                        rekeningKoran4b6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran_4b"
                      onChangeFile={onChangeRekeningKoran4b}
                      text1="Unggah Rekening Koran 4 Bulan Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran 4 Bulan Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran4b +
                        (listKYCDocs.rekening_koran_4b
                          ? listKYCDocs.rekening_koran_4b.reason
                            ? ` - ${listKYCDocs.rekening_koran_4b.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran_4b.id)}
                      refinput={refs.rekeningKoran4b}
                      isLoading={isLoading === "rekeningKoran4b"}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-around" spacing={1}>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran5b,
                        rekeningKoran5b2,
                        rekeningKoran5b3,
                        rekeningKoran5b4,
                        rekeningKoran5b5,
                        rekeningKoran5b6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran_5b"
                      onChangeFile={onChangeRekeningKoran5b}
                      text1="Unggah Rekening Koran 5 Bulan Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran 5 Bulan Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran5b +
                        (listKYCDocs.rekening_koran_5b
                          ? listKYCDocs.rekening_koran_5b.reason
                            ? ` - ${listKYCDocs.rekening_koran_5b.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran_5b.id)}
                      refinput={refs.rekeningKoran5b}
                      isLoading={isLoading === "rekeningKoran5b"}
                    />
                  </Grid>
                  <Grid item>
                    <LVFileInputMultiple
                      srcImage={[
                        rekeningKoran6b,
                        rekeningKoran6b2,
                        rekeningKoran6b3,
                        rekeningKoran6b4,
                        rekeningKoran6b5,
                        rekeningKoran6b6,
                      ]}
                      height={200}
                      width={240}
                      fontSize={10}
                      id="rekening_koran_6b"
                      onChangeFile={onChangeRekeningKoran6b}
                      text1="Unggah Rekening Koran 6 Bulan Lalu"
                      text2="Maksimal ukuran 5MB PDF, RAR, atau ZIP"
                      text3=""
                      title="Rekening Koran 6 Bulan Lalu"
                      titleFontSize={12}
                      subtitle={
                        listStatus.rekeningKoran6b +
                        (listKYCDocs.rekening_koran_6b
                          ? listKYCDocs.rekening_koran_6b.reason
                            ? ` - ${listKYCDocs.rekening_koran_6b.reason}`
                            : ""
                          : "")
                      }
                      subtitleFontSize={12}
                      accept=".pdf, .rar,.zip"
                      deleteFile={() => deleteAssetKYCDocs(listKYCDocs.rekening_koran_6b.id)}
                      refinput={refs.rekeningKoran6b}
                      isLoading={isLoading === "rekeningKoran6b"}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </>
          ) : layout === "syarat" ? (
            <>
              <MDBox display="flex" mt={5} width="60%" height="100%" justifyContent="space-evenly">
                <MDBox
                  width="300px"
                  height="100%"
                  p={3}
                  borderRadius="xl"
                  sx={{ border: "2px solid #0D6ECD" }}
                >
                  <MDTypography sx={{ fontSize: "15px", fontWeight: "bold", color: "#0D6ECD" }}>
                    Langkah 1
                  </MDTypography>
                  <MDTypography sx={{ fontSize: "12px", height: "60px" }}>
                    Untuk mengisi surat pernyataan, silahkan unduh berkas surat terlebih dahulu
                    untuk diisi dan ditandatangani.
                  </MDTypography>
                  <MDBox width="100%" display="flex">
                    <MDButton
                      onClick={handleDownload}
                      sx={{
                        mx: "auto",
                        mt: 4,
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                      }}
                      color="info"
                      variant="contained"
                    >
                      Unduh Berkas Surat
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDBox
                  width="300px"
                  height="100%"
                  p={3}
                  borderRadius="xl"
                  sx={{ border: "2px solid #0D6ECD" }}
                >
                  <MDTypography sx={{ fontSize: "15px", fontWeight: "bold", color: "#0D6ECD" }}>
                    Langkah 2
                  </MDTypography>
                  <MDTypography sx={{ fontSize: "12px", height: "60px" }}>
                    Setelah surat diisi dan ditandatangani, silahkan unggah berkas surat tersebut.
                  </MDTypography>
                  <MDBox width="100%" display="flex">
                    <MDButton
                      component="label"
                      color="info"
                      variant="contained"
                      sx={{
                        mx: "auto",
                        mt: 4,
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      Unggah Berkas Surat
                      <input
                        type="file"
                        id="surat_pernyataan"
                        refinput={refs.suratPernyataan}
                        hidden
                        onChange={onChangeSuratPernyataan}
                        accept=".pdf, .rar,.zip"
                      />
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} display={suratPernyataan === null ? "none" : "flex"}>
                    <MDTypography align="center" color="dark" sx={{ fontSize: 12, width: "100%" }}>
                      surat_pernyataan.pdf
                    </MDTypography>
                    <MDBox
                      onClick={() => deleteAssetKYCDocs(listKYCDocs.surat_pernyataan.id)}
                      sx={{ cursor: "pointer" }}
                      mr={1}
                    >
                      <MDTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </>
          ) : null}
        </>
      );
    }
  };

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Informasi Perusahaan" />
      <MDBox
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: 2,
          height: "100%",
          mt: 5,
          pr: 3,
          pt: 3,
          pb: 5,
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
      >
        <MDBox
          pb={2}
          mb={2}
          px={3}
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: "3px solid #e1e1e1" }}
        >
          <MDBox>
            <MDTypography sx={{ fontSize: "12px" }}>
              {anCompanyKycStatus.status === "created"
                ? "Dengan melengkapi informasi perusahaan, Anda berpeluang untuk mendapat pendanaan dari lembaga jasa keuangan yang bekerjasama dengan Kentara."
                : anCompanyKycStatus.status === "rejected"
                ? "Mohon maaf, ada data yang harus Anda perbaiki sebelum bisa mengajukan pendanaan. Catatan Perbaikan: Foto KTP tidak jelas, rekening koran 3 bulan buram"
                : "Selamat, sekarang Anda bisa mengajukan pendanaan ke lembaga jasa keuangan! Ajukan Dana"}
            </MDTypography>
          </MDBox>
          <MDBox
            borderRadius="xl"
            display="flex"
            px={2}
            py={0.5}
            bgcolor="#e1e1e1"
            sx={{ bgcolor: "#e1e1e1" }}
          >
            <img
              src={
                anCompanyKycStatus.status === "created"
                  ? "/merah.svg"
                  : anCompanyKycStatus.status === "rejected"
                  ? "/merah.svg"
                  : "/hijau.svg"
              }
              alt=""
            />
            <MDTypography ml={2} sx={{ fontSize: "12px" }}>
              {anCompanyKycStatus.status === "created"
                ? "Dalam Verifikasi"
                : anCompanyKycStatus.status === "rejected"
                ? "Perlu Perbaikan"
                : "Sudah Diverifikasi"}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex">
          <MDBox
            display="flex"
            flexDirection="column"
            width="30%"
            mr={8}
            mt={4}
            sx={{ borderRight: "2px solid #e1e1e1" }}
          >
            <div onClick={() => setLayout("datadiri")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "datadiri" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/user.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Data diri
                </MDTypography>
              </MDBox>
            </div>
            <div onClick={() => setLayout("legalitas")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "legalitas" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/buildings-small.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Legalitas Perusahaan
                </MDTypography>
              </MDBox>
            </div>
            <div onClick={() => setLayout("identitas")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "identitas" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/id.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Identitas Pengurus, Direksi{anCompany.type !== "PO" ? ", dan Komisaris" : ""}
                </MDTypography>
              </MDBox>
            </div>
            <div onClick={() => setLayout("kinerja")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "kinerja" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/chart.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Kinerja Keuangan Perusahaan
                </MDTypography>
              </MDBox>
            </div>
            <div onClick={() => setLayout("syarat")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "syarat" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/file.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Syarat dan Ketentuan
                </MDTypography>
              </MDBox>
            </div>
          </MDBox>
          {onReturn()}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default RegisterBorrower;
